// EditExtrasGroup.js
import { useAppContext } from '../../contexts/AppContext';
import { Flex, Tooltip, IconButton, UnorderedList, ListItem } from '@chakra-ui/react';
import { Plus } from "lucide-react";
import OptionsManager from "./OptionsManager";

const ActionButtons = ({ openSubCategoryModal, handleOpenMainCategoryModal, mainCategoryName, addItemWithCategory, categories, itemsByCategory, currentMainCategory }) => {
  
    const { selectedRestaurantId, toggleSubMenu, openSubMenu, closeSubMenu, showOverlay, settings, mainLanguage } = useAppContext();

  return (
    <Flex
            direction="row"
            alignItems={"center"}
            className="buttons"
            position="relative">
            <div>
              <Tooltip label="Add">
                <IconButton
                  size="sm"
                  className="btn primary"
                  onClick={toggleSubMenu("addActions")}>
                  <Plus size={16} color="white" />
                </IconButton>
              </Tooltip>

              {openSubMenu === "addActions" && (
                <div className="submenu">
                  <UnorderedList>
                    <ListItem
                      onClick={() => {
                        handleOpenMainCategoryModal(null);
                        closeSubMenu();
                      }}>
                      Add menu
                    </ListItem>
                    {mainCategoryName && (
                      <>
                        <ListItem
                          onClick={() => {
                            openSubCategoryModal();
                            closeSubMenu();
                          }}>
                          Add category
                        </ListItem>
                        <ListItem
                          onClick={() => {
                            addItemWithCategory();
                            closeSubMenu();
                          }}>
                          Add item
                        </ListItem>
                      </>
                    )}
                  </UnorderedList>
                  <button onClick={closeSubMenu} className="closeSubmenu">
                    Cancel
                  </button>
                </div>
              )}
              {showOverlay && openSubMenu === "addActions" && (
                <div className="overlay" onClick={closeSubMenu}></div>
              )}
            </div>

            <OptionsManager
              categories={categories}
              itemsByCategory={itemsByCategory}
              settings={settings}
              mainLanguage={mainLanguage}
              selectedRestaurantId={selectedRestaurantId}
              currentMainCategory={currentMainCategory}
            />
          </Flex>
  );
};

export default ActionButtons;