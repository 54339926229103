import React from "react";
import ManageCategories from "./ManageCategories";
import { useAppContext } from "../../contexts/AppContext";
import ManageTags from "./ManageTags";
import ManageExtras from "./ManageExtras";
import { Tooltip } from 'react-tooltip';
import { Flex, Button, Heading, Text } from "@chakra-ui/react";
import { CircleHelp } from 'lucide-react';




const Sidebar = ({
	subCategoryName,
	currentSubCategory,
	categories,
	setCategories,
	itemsByCategory,
	handleOrderChange,
	handleCategoryDeletion,
	onAddCategory,
	openAddDishModal,
	uncategorizedItems,
	openEditModal,
	currentMainCategory,
	onDeleteUncategorized,
	openSubCategoryModal
	}) => {

	const { selectedRestaurantId, mainLanguage } = useAppContext();

	return (
		<Flex as="aside" flex={{ desktop: "0 0 320px", mobile: "1" }} direction="column" gap={16}>
				
				<ManageCategories
					categories={categories ? categories.filter((cat) => !cat.isUncategorized) : []}
					setCategories={setCategories}
					itemsByCategory={itemsByCategory}
					onOrderChange={handleOrderChange}
					onCategoryDelete={handleCategoryDeletion}
					onAddCategory={onAddCategory}
					onAddDish={openAddDishModal}
					subCategoryName={subCategoryName}
					selectedRestaurantId={selectedRestaurantId}
					currentMainCategory={currentMainCategory}
					currentSubCategory={currentSubCategory}
					openSubCategoryModal={openSubCategoryModal}
				/>



		<ManageTags />
		
		{currentMainCategory?.activateExtras &&
		
		<ManageExtras
			categories={categories ? categories.filter((cat) => !cat.isUncategorized) : []}
			items={itemsByCategory}
		/>
		}	


			<Flex direction="column" className="widget uncategorized-items" flex={0}>
					<Flex direction="row" alignItems={"center"} justifyContent={"space-between"}>
						<Flex direction="row" gap={1} alignItems={"center"}>
							<h5>Uncategorized</h5>
							<CircleHelp color='var(--grey500)' size={16}  data-tooltip-id="uncategorizedTooltip" data-tooltip-content="Items that don't have a category assigned won't appear on your menu." data-tooltip-place="top" />
							<Tooltip id="uncategorizedTooltip" place="top"	className="toolTip"></Tooltip>
						</Flex>
						{uncategorizedItems.length > 0 && (<Button className="btn secondary" size="xs" onClick={() => onDeleteUncategorized()}>Delete all</Button>)}	
					</Flex>
					


					<div className="widgetBody">
						{uncategorizedItems.length > 0 ? (
					
					<div className="dishes-grid">
						{uncategorizedItems.map((item) => (
							<Flex
								className="category-item"
								p={4}
								borderRadius={"var(--radius-sm)"}
								justifyContent={"space-between"}
								alignItems={"center"}
								cursor={"pointer"}
								key={item.id}
								onClick={() => openEditModal(item)}
							>
								{currentMainCategory?.imageDisplay  && (
										<div className='dish-img'>{item.photo && <img src={item.photo} alt={item.name} />}</div>
								)}									
									<Heading as={"h6"} fontSize={"1em"} fontWeight={"500"} maxWidth="160px" overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>{item[`name_${mainLanguage}`]}</Heading>
									<div
                                      className="dish-price"
                                      >
                                      {item.type === "variable" ? (
                                        Array.isArray(item.variations) ? (
                                          <div className="tag sm">
                                            {item.variations.length} variations
                                          </div>
                                        ) : null
                                      ) : (
                                        <>{item.price}€</>
                                      )}
                                    </div>
								
							</Flex>
						))}
					</div>
				
				
				) : (
				<Text className="no-items">No uncategorized items.</Text>
				)}

				</div>
				

				
			</Flex>
		
		
		</Flex>
	);
};
export default Sidebar;
