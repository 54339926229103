import { Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Textarea } from '@chakra-ui/react';

const CancellationModal = ({ isOpen, onClose, cancellationNote, setCancellationNote, handleConfirmCancellation }) => {

return (
<Modal isOpen={isOpen} onClose={onClose} className='cancellationModal' zIndex={2000}>
<ModalOverlay zIndex={2000}  />
<ModalContent
    containerProps={{
    zIndex: '2000',
  }}>
    <ModalHeader>Confirm cancellation</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
        <Textarea
            placeholder="Please enter a cancellation motive..."
            value={cancellationNote}
            onChange={(e) => setCancellationNote(e.target.value)}
            required
        />
    </ModalBody>
    <ModalFooter>
        <Flex gap={2}>
        <Button className='btn secondary' onClick={onClose}>Cancel</Button>
        <Button 
            className='btn primary' 
            onClick={handleConfirmCancellation}
            disabled={ !cancellationNote }
            >
            Confirm
        </Button>
        </Flex>
    </ModalFooter>
</ModalContent>
</Modal>
);
};
export default CancellationModal;
