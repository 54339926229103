// MainCategoryModal.js
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { Flex, Box, Button, Text, Select, Switch, Input } from "@chakra-ui/react";
import Modal from 'react-modal';
import useModal from '../../hooks/useModal';
import EmojiPicker from 'emoji-picker-react';

Modal.setAppElement('#root');

const MainCategoryModal = ({ isOpen, onClose, onSave, onDelete, editCategory, mainCategoryHasChanges, setMainCategoryHasChanges }) => {
  
  const { toggleSubMenu, openSubMenu, closeSubMenu, showOverlay, settings  } = useAppContext();
  const [name, setName] = useState({});
  const [menuIcon, setMenuIcon] = useState('');
  const [published, setPublished] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [activateExtras, setActivateExtras] = useState(false);
  const [showPrices, setShowPrices] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const {customStyles} = useModal();
  const [selectedNameLanguage, setSelectedNameLanguage] = useState(settings.language[0].value); // Initial language for name

  
  const renderNameFields = () => {
    const allLanguages = [settings.language[0], ...settings.secondaryLanguages];
    return (
      
      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "50%", mobile: "100%" }}
          gap={0}>
          <Text fontWeight={600} color={"var(--heading)"}>Name of the menu</Text>
          <Text maxW={"85%"} fontSize={14}>Enter the name of the menu.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
          <Flex className="languageButtons" gap={1} position={"absolute"} top={2.5} right={4} zIndex={1}>
            {allLanguages.map((lang) => (
              <Button key={lang.value} onClick={() => setSelectedNameLanguage(lang.value)} p={0} height="unset" minWidth="unset" borderRadius={"16px"} overflow={"hidden"} className={selectedNameLanguage === lang.value ? "active" : ""} opacity='.4' title={lang.label}>
                <img src={`/images/flags/${lang.value}.svg`} alt={lang.label} width="18px" height="18px" />
              </Button>
            ))}
        </Flex>
        <Input
          flex={"0 1 !important"}
          type="text"
          name={`name_${selectedNameLanguage}`}
          id={`name_${selectedNameLanguage}`}
          placeholder={`Menu name (${selectedNameLanguage})`}
          value={name[selectedNameLanguage] || ''}
          onChange={(e) => handleNameChange(selectedNameLanguage, e.target.value)}
          required
        />
        </Flex>
      </Flex>


      
  
    );
  };
  
  const onEmojiClick = (emojiObject) => {
    setMenuIcon(emojiObject.emoji);
    setMainCategoryHasChanges(true);
    setShowEmojiPicker(false);
  };
  
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  
  
  const handleChange = (setterFunction) => (e) => {
    setterFunction(e.target.value);
    setMainCategoryHasChanges(true);
  };
  const handleNameChange = (lang, value) => {
    setName(prev => ({ ...prev, [lang]: value }));
    setMainCategoryHasChanges(true);
  };

  const handleDeleteIcon = () => {
    setMenuIcon('');
    setMainCategoryHasChanges(true); // Marquer comme modifié
};



useEffect(() => {
  if (isOpen) {
    if (editCategory) {
      // Si on édite une catégorie existante
      const newNameState = {};
      const allLanguages = [settings.language[0], ...settings.secondaryLanguages];
      
      allLanguages.forEach(lang => {
        const fieldName = `name_${lang.value}`;
        newNameState[lang.value] = editCategory[fieldName] || '';
      });
      
      setName(newNameState);
      setMenuIcon(editCategory.menuIcon || '');
      setPublished(editCategory.published || false);
      setShowImages(editCategory.imageDisplay || false);
      setActivateExtras(editCategory.activateExtras || false);
      setShowPrices(editCategory.showPrices || false); 
      setSelectedNameLanguage(settings.language[0].value); // Réinitialiser la langue sélectionnée à la langue principale
    } else {
      // Si on crée une nouvelle catégorie
      setName({});
      setMenuIcon('');
      setPublished(false);
      setShowImages(true);
      setActivateExtras(false);
      setShowPrices(false); 
      setSelectedNameLanguage(settings.language[0].value); // Sélectionner la langue principale par défaut
    }
  }
}, [isOpen, editCategory, settings.language, settings.secondaryLanguages]);


  
  



  const handleSave = (e) => {
    e.preventDefault();

    const categoryData = {
        id: editCategory ? editCategory.id : undefined, // Utiliser l'ID existant pour une édition
        name,
        published,
        imageDisplay: showImages,
        menuIcon,
        activateExtras,
        showPrices,
    };
    onSave(categoryData);
    onClose();
    setMainCategoryHasChanges(false);
};

  
  

  const handleDelete = () => {
    const isConfirmed = window.confirm("Are you sure you want to delete this category?");
    if (isConfirmed) {
      onDelete(editCategory);
      onClose();
      setMainCategoryHasChanges(false);
    }
  };


  if (!isOpen) return null;


  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={'modal'} style={customStyles}>
      
      <Flex className="modal-content" w={"640px"} >
      <form onSubmit={(e) => e.preventDefault()}>
      
      <div className="modalTopBar">

            <div className="closeModal">
              <span className="close" onClick={onClose}> 
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z" fill="black"/>
                </svg>  
              </span>
            </div>         
            <div className="modalTitle">{editCategory ? "Edit menu" : "Add a new menu"}</div>   
            <div className="actionButtons">
            
               <Button type="submit" size="sm" className="btn primary" onClick={handleSave} isDisabled={!mainCategoryHasChanges}>
               {editCategory ? 'Save changes' : 'Add menu'}
              </Button>
            
            
            {editCategory && (
            <div className='btn simple itemActions subMenuToggle' onClick={toggleSubMenu('mainCategoryActions')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z" fill="black"/>
              </svg>
              
              {openSubMenu === 'mainCategoryActions' && (
                <div className='submenu'>
                  <ul>
                    <li onClick={handleDelete} style={{ color: 'var(--error)' }}>Delete menu</li>
                  </ul>
                  <button onClick={closeSubMenu} className='closeSubmenu'>Cancel</button>
                </div>
              )}
            {showOverlay && openSubMenu === 'mainCategoryActions' && (
       					 <div className="overlay" onClick={closeSubMenu}></div>
        			)}
            </div>
             )}
            </div>
      </div>
        
        
        <Flex className='modalInner' gap={8} p={8}>
          
          {renderNameFields()}


          <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "50%", mobile: "100%" }}>
          <Text fontWeight={600} color={"var(--heading)"}>Icon</Text>
          <Text maxW={"85%"} fontSize={14}>Select an icon.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
            <Flex className="icon-selection" gap={4} alignItems={"center"}>
              <Flex w={"42px"} h={"42px"} alignItems={"center"} justifyContent={"center"} fontSize={32} p={1} border={"1px solid var(--borders)"} borderRadius={8} onClick={toggleEmojiPicker} cursor={"pointer"}>{menuIcon}</Flex>
              {menuIcon ? (
                <Button size={"sm"} variant={"outline"} onClick={handleDeleteIcon}>Remove icon</Button>
              ) : ( <Button size={"sm"} variant={"outline"} onClick={toggleEmojiPicker}>Choose icon</Button> )}
            </Flex>
            {showEmojiPicker && <EmojiPicker onEmojiClick={onEmojiClick} skinTonesDisabled={true} searchDisabled={true} width='100%' className='emojiPicker' style={{ position: 'absolute', top: '100%', zIndex: '99', height: '320px' }} />}
        </Flex>
      </Flex>


          
          <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "50%", mobile: "100%" }}>
          <Text fontWeight={600} color={"var(--heading)"}>Status</Text>
          <Text maxW={"85%"} fontSize={14}>Choose whether the menu is published.</Text>
        </Flex>
        <Flex direction="column" gap={2} justifyContent={"flex-start"}>
            <Select value={published ? 'published' : 'unpublished'} onChange={(e) => handleChange(() => setPublished(e.target.value === 'published'))(e)}>  
              <option value="published">Published</option>
              <option value="unpublished">Unpublished</option>
            </Select>
        </Flex>
      </Flex>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "50%", mobile: "100%" }}>
          <Text fontWeight={600} color={"var(--heading)"}>Show prices</Text>
          <Text maxW={"85%"} fontSize={14}>Choose whether to show prices.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1} justifyContent={"flex-start"}>
              <Switch
                id="prices-switch"
                isChecked={showPrices} 
                onChange={(e) => { setShowPrices(e.target.checked); setMainCategoryHasChanges(true); }}
                />
        </Flex>
      </Flex>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "50%", mobile: "100%" }}>
          <Text fontWeight={600} color={"var(--heading)"}>Show photos</Text>
          <Text maxW={"85%"} fontSize={14}>Choose whether to show photos.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1} justifyContent={"flex-start"}>
              <Switch
                id="images-switch"
                isChecked={showImages} 
                onChange={(e) => { setShowImages(e.target.checked); setMainCategoryHasChanges(true); }}
              />
        </Flex>
      </Flex>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}>
        <Flex
          direction={"column"}
          w={{ desktop: "50%", mobile: "100%" }}>
          <Text fontWeight={600} color={"var(--heading)"}>Extras</Text>
          <Text maxW={"85%"} fontSize={14}>Choose whether to activate extras.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1} justifyContent={"flex-start"}>
              <Switch
                id="extras-switch"
                isChecked={activateExtras} 
                onChange={(e) => { setActivateExtras(e.target.checked); setMainCategoryHasChanges(true); }}
              />        
        </Flex>
      </Flex>


        
          
      

          </Flex>

        
        </form>
      </Flex>
    </Modal>
  );
};

export default MainCategoryModal;
