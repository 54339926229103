import useStrictModeDroppable from "../../hooks/useStrictModeDroppable";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAppContext } from "../../contexts/AppContext";
import { Flex } from '@chakra-ui/react';
import useMainCategories from "../../hooks/useMainCategories";


const StrictModeDroppableComponent = ({ droppableId, children }) => {
    const { enabled } = useStrictModeDroppable();
  
    if (!enabled) {
      return null;
    }
  
    return (
      <Droppable key={droppableId} droppableId={droppableId} direction="horizontal">
        {(provided, snapshot) => children(provided, snapshot)}
      </Droppable>
    );
  };


const MenuSwitcher = () => {
  
    const { selectedRestaurantId, mainLanguage } = useAppContext();
    
    const {
        mainCategories,
        mainCategoryName,
        setMainCategoryName,
        handleOnDragEnd   
    } = useMainCategories(selectedRestaurantId);

    const handleCategoryClick = (categoryId) => {
        setMainCategoryName(categoryId);
        window.scrollTo(0, 0);
    };
    

  return (
    
    
    <DragDropContext onDragEnd={handleOnDragEnd}>
          <StrictModeDroppableComponent droppableId="mainCategories" key="mainCategories">
            {(provided) => (
              <Flex
                direction="row"
                className="tabLinks"
                alignItems="center"
                pr="8px"
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {mainCategories.map((category, index) => (
                  <Draggable
                    key={category.id}
                    draggableId={category.id}
                    index={index}>
                    {(provided) => (
                      <Flex
                        direction="row"
                        alignItems="center"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`tabLink ${
                          mainCategoryName === category.id ? "active" : ""
                        }`}
                        onClick={() => handleCategoryClick(category.id)}>
            
                        <span className="mainCat-icon">
                          {category.menuIcon}
                        </span>
                        <span className="mainCat-name">
                          {category[`name_${mainLanguage}`]}
                        </span>
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </StrictModeDroppableComponent>
        </DragDropContext>

  );
};

export default MenuSwitcher;
