import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { db, storage } from "../firebaseConfig";
import { doc, updateDoc, FieldValue } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useAppContext } from "../contexts/AppContext";
import { toast } from "react-toastify";
import ShopSettings from "../components/settings/tabs/ShopSettings";
import {
  Button,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import ReservationsSettings from "../components/settings/tabs/ReservationsSettings";
import MenusSettings from "../components/settings/tabs/MenusSettings";
import deepEqual from "fast-deep-equal"; // Utilisation d'une fonction pour comparer les objets profondément
import GeneralSettings from "../components/settings/tabs/GeneralSettings";

const Settings = () => {
  const location = useLocation();

  // Extraire le paramètre `tab` de l'URL
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");

  // Map des onglets pour correspondre à leurs indices
  const tabIndexMap = {
    general: 0,
    menus: 1,
    shop: 2,
    reservations: 3,
  };

  // Déterminer l'index du tab actif basé sur l'URL
  const [tabIndex, setTabIndex] = React.useState(tabIndexMap[activeTab] || 0);

  useEffect(() => {
    if (activeTab && tabIndexMap[activeTab] !== undefined) {
      setTabIndex(tabIndexMap[activeTab]);
    }
  }, [activeTab]);

  const { selectedRestaurantId, menuAdminBarZIndex, settings, setSettings, isSettingsLoading, isSelectedRestaurantLoading } = useAppContext();

  const [initialSettings, setInitialSettings] = useState(null); // Stocker les paramètres initiaux
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Suivre les changements

 
  // Initialise `initialSettings` seulement après que `settings` et `selectedRestaurantId` sont complètement chargés
  useEffect(() => {
    if (!isSelectedRestaurantLoading && !isSettingsLoading && settings && !initialSettings) {
      setInitialSettings(JSON.parse(JSON.stringify(settings)));
      setHasUnsavedChanges(false); // Assurez-vous que le bouton est désactivé après le chargement initial
    }
  }, [isSelectedRestaurantLoading, isSettingsLoading, settings, initialSettings]);

  // Vérifie les changements après chaque modification des paramètres
  useEffect(() => {
    if (initialSettings && settings) {
      const changesExist = !deepEqual(initialSettings, settings);
      setHasUnsavedChanges(changesExist); // Met à jour l'état du bouton
    }
  }, [settings, initialSettings]);

  const checkForUnsavedChanges = (newSettings) => {
    console.log("Manual check for unsaved changes - initialSettings:", initialSettings, "newSettings:", newSettings);
  
    if (initialSettings && Object.keys(initialSettings).length > 0) {
      // Compare les objets et affiche si les settings sont identiques ou non
      const changesExist = !deepEqual(initialSettings, newSettings);
      console.log("Unsaved changes exist:", changesExist);
      setHasUnsavedChanges(changesExist); // Met à jour le bouton Save
    } else {
      console.log("Initial settings not yet loaded or empty, skipping check.");
    }
  };
  
  

  
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const keys = name.split(".");
  
    setSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings };
      let temp = updatedSettings;
  
      // Mise à jour des paramètres modifiés dans la structure imbriquée
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          temp[key] = value;
        } else {
          temp[key] = { ...temp[key] };
          temp = temp[key];
        }
      });
  
      // Vérifie explicitement les changements après la modification
      checkForUnsavedChanges(updatedSettings);
      return updatedSettings; // Retourne les settings mis à jour
    });
  };
  
  
  
  const handleFileChange = async (e, logoType = "shop") => {
    const file = e.target.files[0];
    if (file) {
      try {
        const fileName = `${selectedRestaurantId}/${logoType}/${file.name}`;
        const storageRef = ref(storage, `logos/${fileName}`);
        const uploadResult = await uploadBytes(storageRef, file);
        const logoUrl = await getDownloadURL(uploadResult.ref);

        setSettings((prevSettings) => {
          const newSettings = {
            ...prevSettings,
            logo: {
              ...(prevSettings.logo || {}),
              [logoType]: logoUrl,
            },
          };

          const settingsDocRef = doc(
            db,
            `restaurants/${selectedRestaurantId}/settings`,
            "config"
          );
          updateDoc(settingsDocRef, { [`logo.${logoType}`]: logoUrl }).catch(
            console.error
          );

          checkForUnsavedChanges(newSettings);
          return newSettings;
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleSaveSettings = async () => {
    if (!hasUnsavedChanges) return;

    try {
      const settingsRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/settings`,
        "config"
      );
      await updateDoc(settingsRef, settings); // Save updated settings to Firestore
      setInitialSettings(JSON.parse(JSON.stringify(settings))); // Update initial settings to the latest saved version
      setHasUnsavedChanges(false); // Reset unsaved changes state
      toast.success("Settings saved successfully");
    } catch (error) {
      console.error("Error saving settings:", error);
      toast.error("Error saving settings");
    }
  };

  const handleDeleteLogo = async (logoType) => {
    const logoUrl = settings.logo?.[logoType];
    if (!logoUrl) return;

    try {
      const logoRef = ref(storage, logoUrl);
      await deleteObject(logoRef);

      setSettings((prevSettings) => ({
        ...prevSettings,
        logo: {
          ...prevSettings.logo,
          [logoType]: "", // Mettez simplement la clé correspondante à une chaîne vide
        },
      }));

      const settingsDocRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/settings`,
        "config"
      );
      await updateDoc(settingsDocRef, {
        [`logo.${logoType}`]: FieldValue.delete(), // Utilisez FieldValue.delete() pour supprimer la clé spécifique
      });
    } catch (error) {
      console.error(`Error deleting ${logoType} logo:`, error);
    }
  };


  return (
    <>
      <Flex
        padding={{ desktop: 12, mobile: 8 }}
        pb={{ desktop: 8, mobile: "89px" }}
        maxW="1320px"
        direction={"column"}
        gap={8}
        flex="1">
        <h3>Settings</h3>

        <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
          <Flex
            justifyContent="space-between"
            direction="row"
            w="100%"
            alignItems="center"
            position="sticky"
            top="0"
            bg={{ desktop: "white", mobile: "var(--grey100)" }}
            px={0}
            maxWidth="100vw"
            borderBottom="1px solid var(--borders)"
            style={{ zIndex: menuAdminBarZIndex }}>
            <TabList className="tabLinks" w="100%">
              <Tab className="tabLink">General</Tab>
              <Tab className="tabLink">Menus</Tab>
              {settings.modules?.shop && <Tab className="tabLink">Shop</Tab>}
              {settings.modules?.reservations && (
                <Tab className="tabLink">Reservations</Tab>
              )}
            </TabList>
          </Flex>

          <TabPanels>
            <TabPanel>
              <GeneralSettings handleInputChange={handleInputChange}  />
            </TabPanel>
            <TabPanel>
              <MenusSettings onChange={handleInputChange} />
            </TabPanel>
            {settings.modules?.shop && (
              <TabPanel>
                <ShopSettings
                  handleFileChange={handleFileChange}
                  onChange={handleInputChange}
                  onDelete={handleDeleteLogo}
                  checkForUnsavedChanges={checkForUnsavedChanges}
                />
              </TabPanel>
            )}
            {settings.modules?.reservations && (
              <TabPanel>
                <ReservationsSettings onChange={handleInputChange} />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Flex>
      <Flex
        position={"sticky"}
        bottom={{ desktop: 0, mobile: "90.5px" }}
        w={"100%"}
        py={4}
        px={8}
        justifyContent={"flex-end"}
        bg="white"
        borderTop={"1px solid var(--borders)"}>
        <Button
  size="md"
  onClick={handleSaveSettings}
  className="btn primary"
  isDisabled={isSettingsLoading || !hasUnsavedChanges} // Bouton désactivé s'il n'y a pas de changements
>
  Save changes
</Button>

      </Flex>
    </>
  );
};

export default Settings;
