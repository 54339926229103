import { extendTheme } from "@chakra-ui/react";

const useCustomChakra = () => {
  
  const breakpoints = {
    "2xl": "1920px",
    desktop: "960px",
    mobile: "0px"
  };
  
  const colors = {
    brand: {
      50: '#FFF9F9',
      100: '#FDDDDC',
      200: '#FBC3C1',
      300: '#F9AAA6',
      400: '#f25c54',
      500: '#E65A53',
      600: '#D95851',
    }
  }

  // Extend the Switch theme
  const components = {
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            bg: 'brand.400', // This will apply your brand color when the switch is checked
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand', // Default colorScheme set to your brand
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderWidth: '1px',
          borderRadius: '3px',
          _checked: {
            bg: 'brand.400', // Background color when checkbox is checked
            borderColor: 'brand.400', // Border color when checkbox is checked
            color: 'white', // Checkmark color
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand', // Default colorScheme set to your brand
      },
    },
  };

  const theme = extendTheme({ breakpoints, colors, components });

  return {
    breakpoints,
    theme,
    colors
  };
};

export default useCustomChakra;
