import { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, query, where, onSnapshot, orderBy, getDocs, deleteDoc, updateDoc, doc, addDoc, Timestamp, getDoc } from "firebase/firestore";
import { useAppContext } from "../contexts/AppContext";
import { toast } from "react-toastify";

const useOrders = ( order ) => {

  const { selectedRestaurantId, settings, userData } = useAppContext();
  const [orders, setOrders] = useState([]);

  const [isSearching, setIsSearching] = useState(false); // Pour suivre l'état de la recherche
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);

  const [cancellationNote, setCancellationNote] = useState('');
  
  const [deliveryOrders, setDeliveryOrders] = useState([]);
  const [filteredDeliveryOrders, setFilteredDeliveryOrders] = useState([]); // Commandes filtrées pour le livreur connecté

  const [isLoading, setIsLoading] = useState(false); // Ajout de l'état de chargement
  
  
  const [localOrder, setLocalOrder] = useState(order || null);
  

 
  // Charger les commandes paginées
  const loadOrders = (reset = false) => {
    if (!selectedRestaurantId) {
      return;
    }

    setIsLoading(true); // Commencer le chargement
    const ordersRef = collection(db, `restaurants/${selectedRestaurantId}/orders`);
    const q = query(ordersRef, orderBy("scheduledTime", "desc")); // Trier par scheduledTime

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedOrders = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : null,
      }));

      setOrders(reset ? fetchedOrders : [...orders, ...fetchedOrders]);
      setIsLoading(false); // Terminer le chargement une fois les commandes récupérées
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    // Vérifier que selectedRestaurantId est disponible avant de charger les commandes
    if (!selectedRestaurantId || !settings?.modules?.shop) {
      setOrders([]); // S'assurer que les commandes sont vides si pas de restaurant sélectionné
      setIsLoading(false);
      return;
    }

    loadOrders(true);
  }, [selectedRestaurantId, settings]);

  
  
  const searchOrders = async (queryStr) => {
    if (!selectedRestaurantId || !settings?.modules?.shop) {
      setFilteredOrders([]); // Ensure reservations is an empty array
      return;
    }
    setIsSearching(true);
    const ordersRef = collection(db, `restaurants/${selectedRestaurantId}/orders`);
  
    const idQuery = query(
      ordersRef,
      where('orderId', '>=', queryStr),
      where('orderId', '<=', queryStr + '\uf8ff'),
      orderBy('scheduledTime', 'desc') 
    );
  
    let results = [];

    const idSnapshot = await getDocs(idQuery);
    idSnapshot.forEach((doc) => {
      const data = doc.data();
      const createdAt = data.createdAt ? data.createdAt.toDate() : null;
      
      results.push({
        id: doc.id,
        ...data,
        fullDate: createdAt
          ? createdAt.toLocaleString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
          : "N/A",
      });
    });
  
    // Supprimer les doublons
    results = results.filter((value, index, self) =>
      index === self.findIndex((t) => t.id === value.id)
    );
  
    setFilteredOrders(results);
    setIsSearching(false);
    
    return results;
  };
    
  


// Load Delivery orders
useEffect(() => {
  if (!selectedRestaurantId || !settings?.modules?.shop || !userData) {
    setOrders([]);
    return;
  }

  const ordersRef = collection(db, `restaurants/${selectedRestaurantId}/orders`);
  const q = query(ordersRef, where("status", "==", "sent"), orderBy("createdAt", "desc"));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const fetchedOrders = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    setDeliveryOrders(fetchedOrders); 
    const userDeliveryOrders = fetchedOrders.filter(order => {
      return order.delivererId === userData.userId; // Utilisez userData.id pour filtrer
    });

    setFilteredDeliveryOrders(userDeliveryOrders); // Mettre à jour avec les commandes filtrées par livreur
  });

  return () => unsubscribe();
}, [selectedRestaurantId, userData]);



const deleteOrder = async (selectedRestaurantId, orderId) => {
  const isConfirmed = window.confirm("Are you sure you want to delete this order? This action cannot be undone.");
  
  if (isConfirmed) {
    const orderRef = doc(db, `restaurants/${selectedRestaurantId}/orders`, orderId);
    
    try {
      // Récupérer les informations de la commande avant suppression
      const orderDoc = await getDoc(orderRef);
      if (!orderDoc.exists()) {
        toast.error("Order not found.");
        return;
      }
      
      const orderData = orderDoc.data();
      const customerEmail = orderData.customerDetails.email;
      const customerRef = doc(db, `restaurants/${selectedRestaurantId}/customers`, customerEmail);

      // Récupérer le document client correspondant
      const customerDoc = await getDoc(customerRef);
      if (customerDoc.exists()) {
        const customerData = customerDoc.data();

        // Mettre à jour les informations du client
        const newNumberOfOrders = (customerData.numberOfOrders || 0) - 1;
        const orderTotal = parseFloat(orderData.total.replace(',', '.'));
        const newTotalSpent = (parseFloat(customerData.totalSpent || 0) - orderTotal).toFixed(2);
        const newAverageSpent = newNumberOfOrders > 0 ? (newTotalSpent / newNumberOfOrders).toFixed(2) : "0.00";
        
        const updatePayload = {
          numberOfOrders: newNumberOfOrders,
          totalSpent: newTotalSpent.replace('.', ','),
          averageSpent: newAverageSpent.replace('.', ',')
        };
        
        // Mise à jour du document client
        await updateDoc(customerRef, updatePayload);
      } else {
        console.warn("Customer not found. Skipping customer update.");
      }

      // Suppression de la commande
      await deleteDoc(orderRef);
      toast.success(`Order ${orderId} was successfully deleted.`);
      setIsOrderModalOpen(false);
      
    } catch (error) {
      console.error("Error during order deletion:", error);
      toast.error("Error during order deletion.");
    }
  } else {
    console.log("Order deletion cancelled.");
  }
};

  const updateOrderStatus = async (
    orderId,
    newStatus,
    newScheduledTime = null,
    cancellationNote = null,
    delivererId = null,
    delivererName = null
  ) => {
    try {
      const orderRef = doc(db, `restaurants/${selectedRestaurantId}/orders`, orderId);
      const orderSnap = await getDoc(orderRef);

      if (!orderSnap.exists()) {
        console.error("Order not found");
        return;
      }

      const orderData = orderSnap.data();

      let updatePayload = { status: newStatus };

      if (newStatus === "cancelled" && cancellationNote) {
        updatePayload.cancellationNote = cancellationNote;
      }

      

      if (newScheduledTime && newScheduledTime instanceof Date) {
        updatePayload.scheduledTime = Timestamp.fromDate(newScheduledTime);
      }

      let logEntry = {
        action: `Status changed to ${newStatus}`,
        timestamp: new Date().toISOString(),
        user: userData ? `${userData.name} ${userData.lastName}` : "Unknown User",
      };

      if (newScheduledTime && newStatus === "confirmed") {
        const minutesAdded = (newScheduledTime.getTime() - orderData.scheduledTime.toDate().getTime()) / 60000;
        logEntry.action = `Status changed to confirmed with +${minutesAdded} minutes`;
      }

      // Mettre à jour le statut de paiement si la commande est "completed"
      if (newStatus === "completed") {
        updatePayload.paymentStatus = "paid";
      } 

      const customerEmail = orderData.customerDetails.email;
      const customersRef = collection(db, `restaurants/${selectedRestaurantId}/customers`);
      const customerQuery = query(customersRef, where("email", "==", customerEmail));
      const customerSnapshot = await getDocs(customerQuery);

      if (!customerSnapshot.empty) {
        const customerDoc = customerSnapshot.docs[0];
        const customerData = customerDoc.data();

        const initialTotalSpent = customerData.totalSpent ? customerData.totalSpent.toString().replace(',', '.') : "0";

        if (orderData.status === "completed" && newStatus !== "completed") {
          // Inverse the calculations since the order is no longer completed
          const newNumberOfOrders = (customerData.numberOfOrders || 0) - 1;
          let newTotalSpent = (parseFloat(initialTotalSpent) - parseFloat(orderData.total.replace(',', '.'))).toFixed(2);

          // Ensure totalSpent is never negative
          if (newTotalSpent < 0) {
            newTotalSpent = "0.00";
          }

          const newAverageSpent = newNumberOfOrders > 0 ? (newTotalSpent / newNumberOfOrders).toFixed(2) : "0.00";

          await updateDoc(customerDoc.ref, {
            numberOfOrders: newNumberOfOrders,
            totalSpent: newTotalSpent.replace('.', ','),
            averageSpent: newAverageSpent.replace('.', ','),
          });

        } else if (newStatus === "completed") {
          // Handle the case when the status is changed to "completed"
          const newNumberOfOrders = (customerData.numberOfOrders || 0) + 1;
          const newTotalSpent = (parseFloat(initialTotalSpent) + parseFloat(orderData.total.replace(',', '.'))).toFixed(2);
          const newAverageSpent = (newTotalSpent / newNumberOfOrders).toFixed(2);

          await updateDoc(customerDoc.ref, {
            numberOfOrders: newNumberOfOrders,
            totalSpent: newTotalSpent.replace('.', ','),
            averageSpent: newAverageSpent.replace('.', ','),
          });
        }
      } else {
        
        // Customer does not exist, create a new one
        if (newStatus === "completed") {
          const newCustomerData = {
            email: orderData.customerDetails.email,
            firstName: orderData.customerDetails.firstName,
            lastName: orderData.customerDetails.lastName,
            phoneNumbers: {
              [orderData.customerDetails.phone]: true
            },
            numberOfOrders: 1,
            totalSpent: orderData.total.replace('.', ','),
            averageSpent: orderData.total.replace('.', ','),
            customerId: orderData.customerDetails.userId,
            newsletter: false,  // set the default value for newsletter if needed
          };

          await addDoc(customersRef, newCustomerData);
        }
      }

      if (newStatus === "sent" && delivererId && delivererName) {
        logEntry.action = `Status changed to ${newStatus} and assigned to ${delivererName}`;
        updatePayload.delivererId = delivererId;
      } else if (newStatus !== "sent" && delivererId && delivererName) {
        logEntry.action = `Assigned to ${delivererName}`;
        updatePayload.delivererId = delivererId;
      }

      if (orderData.historyLog) {
        updatePayload.historyLog = [...orderData.historyLog, logEntry];
      } else {
        updatePayload.historyLog = [logEntry];
      }

      await updateDoc(orderRef, updatePayload);
      toast.success("Order status updated");
    } catch (error) {
      console.error("Error updating order status:", error);
      toast.error("Error updating order status");
    }
  };





  


  
  const assignDeliverer = async (orderId, delivererId, delivererName) => {
    const orderRef = doc(db, `restaurants/${selectedRestaurantId}/orders`, orderId);
    const orderSnap = await getDoc(orderRef);
  
    if (!orderSnap.exists()) {
      console.error("Order not found");
      return;
    }
  
    const orderData = orderSnap.data();
    
    // Build update payload
    let updatePayload = { delivererId };
  
    // Log entry for deliverer assignment
    const logEntry = {
      action: `Assigned to ${delivererName}`,
      timestamp: new Date().toISOString(),
      user: userData ? `${userData.name} ${userData.lastName}` : "Unknown User",
    };
  
    // Append log entry to the order's historyLog
    if (orderData.historyLog) {
      updatePayload.historyLog = [...orderData.historyLog, logEntry];
    } else {
      updatePayload.historyLog = [logEntry];
    }
  
    try {
      await updateDoc(orderRef, updatePayload);
    } catch (error) {
      console.error("Error assigning deliverer:", error);
      toast.error("Error assigning deliverer");
    }
  };
  
  


  const processRefund = async (orderId, amount) => {
    const orderRef = doc(db, `restaurants/${selectedRestaurantId}/orders`, orderId);
    
    const logEntry = {
      action: `Refund processed for ${amount}€`,
      timestamp: new Date().toISOString(),
      user: userData ? `${userData.name} ${userData.lastName}` : "Unknown User",
    };
  
    try {
      const orderSnap = await getDoc(orderRef);
      if (!orderSnap.exists()) {
        throw new Error("Order not found");
      }
  
      const orderData = orderSnap.data();
      const updatePayload = {
        refundedAmount: amount,
        paymentStatus: "refunded",
        historyLog: orderData.historyLog ? [...orderData.historyLog, logEntry] : [logEntry],
      };
  
      await updateDoc(orderRef, updatePayload);
      toast.success(`Refund of ${amount} processed successfully for order ${orderId}.`);
    } catch (error) {
      console.error("Error processing refund:", error);
      toast.error(`Error processing refund for order ${orderId}`);
    }
  };
  

  const calculateTVA = (orders) => {
    let totalVAT = 0;
    const completedOrders = orders.filter(
      (order) => order.status === "completed"
    );
    completedOrders.forEach((order) => {
      order.cartItems.forEach((item) => {
        // Assurez-vous de convertir le format de nombre européen en format standard si nécessaire
        const price = parseFloat(item.price.replace(",", "."));
        const vatRate = item.vat;
        const vatAmount = (price - price / (1 + vatRate)) * item.quantity;
        totalVAT += Math.round(vatAmount * 100) / 100; // Arrondir à deux décimales ici
      });
    });
    return totalVAT.toFixed(2);
  };

  const calculateTotalCommission = (orders, commissionFee) => {
    const fee = parseFloat(commissionFee.replace(",", ".")) / 100;
    const totalVAT = parseFloat(calculateTVA(orders).replace(",", "."));
    const totalSubTotal = orders.reduce((acc, order) => {
      if (order.status === "completed") {
        return acc + parseFloat(order.subTotal.replace(",", "."));
      }
      return acc;
    }, 0);
    const totalCommission = (totalSubTotal - totalVAT) * fee;
    return totalCommission; // Formate le résultat à deux décimales
  };
  
  // Usage
  const totalCommission = calculateTotalCommission(orders, settings.shopSettings?.fees?.commissionFee);


  const formatScheduledTime = (scheduledDate) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);


    const formattedTime = scheduledDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const scheduledDateString = new Date(
      scheduledDate.getFullYear(),
      scheduledDate.getMonth(),
      scheduledDate.getDate()
    );

    if (scheduledDateString.getTime() === today.getTime()) {
      return `Today, ${formattedTime}`;
    } else if (scheduledDateString.getTime() === tomorrow.getTime()) {
      return `Tomorrow, ${formattedTime}`;
    } else if (scheduledDateString.getTime() === yesterday.getTime()) {
      return `Yesterday, ${formattedTime}`;
    } else {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const formattedDate = scheduledDate.toLocaleDateString("en-GB", options);
      return `${formattedDate}, ${formattedTime}`;
    }
  };
  
  const handleAddTimeToOrder = async (orderId, minutes) => {
    const order = orders.find(order => order.id === orderId);
    if (order && order.scheduledTime) {
      const updatedTime = new Date(order.scheduledTime.toDate().getTime() + minutes * 60000);
      const logMessage = `Status changed to confirmed with +${minutes} minutes`;
      await updateOrderStatus(orderId, "confirmed", updatedTime, logMessage);
  
      // Fetch updated order from the database
      const updatedOrder = await getDoc(doc(db, `restaurants/${selectedRestaurantId}/orders`, orderId));
  
      // Update the localOrder state with the new order data
      if (updatedOrder.exists()) {
        setLocalOrder(updatedOrder.data());
      }
    }
  };
  

  const handleConfirmOrder = async (orderId) => {
    await updateOrderStatus(orderId, "confirmed");
  
    // Fetch updated order from the database
    const updatedOrder = await getDoc(doc(db, `restaurants/${selectedRestaurantId}/orders`, orderId));
  
    // Update the localOrder state with the new order data
    if (updatedOrder.exists()) {
      setLocalOrder(updatedOrder.data());
    }
  };
  



    

  
  return {
    orders,
    loadOrders,
    isLoading,
    searchOrders,
    filteredOrders,
    setFilteredOrders,
    isSearching,
    setIsSearching,
    filteredDeliveryOrders,
    setOrders,
    deleteOrder,
    isOrderModalOpen,
    setIsOrderModalOpen,
    updateOrderStatus,
    calculateTotalCommission,
    totalCommission,
    calculateTVA,
    processRefund,
    cancellationNote,
    setCancellationNote,
    assignDeliverer, 
    formatScheduledTime,
    handleAddTimeToOrder,
    handleConfirmOrder,
    localOrder, setLocalOrder
  };
};

export default useOrders;
