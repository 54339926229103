import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, FormLabel, Input, RadioGroup, Stack, Radio, Select } from "@chakra-ui/react";
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import ReactSelect from 'react-select';

const CouponModal = ({ isOpen, onClose, selectedRestaurantId, onCouponAdded }) => {
  const [name, setName] = useState('');
  const [application, setApplication] = useState('all');
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [couponValue, setCouponValue] = useState('');
  const [couponType, setCouponType] = useState('fixed');
  const [maxUses, setMaxUses] = useState('');
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      const customersRef = collection(db, `restaurants/${selectedRestaurantId}/customers`);
      const customersSnapshot = await getDocs(customersRef);
      const customerOptions = customersSnapshot.docs.map(doc => ({
        value: doc.data().email, // Use email as the value
        label: `${doc.data().firstName} ${doc.data().lastName} (${doc.data().email})`,
      }));
      setCustomers(customerOptions);
    };
  
    fetchCustomers();
  }, [selectedRestaurantId]);
  

  const handleAddCoupon = async () => {
    try {
      const newCoupon = {
        name,
        application: application === 'all' ? 'All customers' : selectedCustomers.map(cust => cust.value),
        couponValue: `${couponValue} ${couponType === 'fixed' ? '€' : '%'}`,
        maxUses: parseInt(maxUses, 10),
        status: 'active', // Default to active
        createdAt: new Date(),
      };
  
      const couponsRef = collection(db, `restaurants/${selectedRestaurantId}/coupons`);
      await addDoc(couponsRef, newCoupon);
  
      if (onCouponAdded) {
        onCouponAdded(); // Refresh the coupons list
      }
  
      onClose(); // Close the modal after adding the coupon
    } catch (error) {
      console.error("Error adding coupon: ", error);
    }
  };
  

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add a New Coupon</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Coupon Name</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter coupon name" />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Application</FormLabel>
            <Select value={application} onChange={(e) => setApplication(e.target.value)}>
              <option value="all">All customers</option>
              <option value="specific">Specific customers</option>
            </Select>

            {application === 'specific' && (
              <ReactSelect
                isMulti
                options={customers}
                value={selectedCustomers}
                onChange={setSelectedCustomers}
                placeholder="Select customers"
              />
            )}
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Coupon Value</FormLabel>
            <Input value={couponValue} onChange={(e) => setCouponValue(e.target.value)} placeholder="Enter value" />
            <RadioGroup onChange={setCouponType} value={couponType} mt={2}>
              <Stack direction="row">
                <Radio value="fixed">€</Radio>
                <Radio value="percentage">%</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Maximum Uses</FormLabel>
            <Input type="number" value={maxUses} onChange={(e) => setMaxUses(e.target.value)} placeholder="Enter max uses" />
          </FormControl>
        </ModalBody>

        <ModalFooter gap={2}>
            <Button className='btn secondary' onClick={onClose}>Cancel</Button>
            <Button className='btn primary' onClick={handleAddCoupon}>Add Coupon</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CouponModal;
