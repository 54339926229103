import React, { useState } from "react";
import Modal from "react-modal";
import { useAppContext } from "../../contexts/AppContext";
import { IconButton, Tooltip, Text, ListItem, UnorderedList } from "@chakra-ui/react";
import { EllipsisVertical } from "lucide-react";
import PdfSettingsModal from "./pdf/PdfSettingsModal";
import { useMenuContext } from "../../contexts/MenuContext";
import ImportModal from "./importModal";
import useImportExport from "../../hooks/useImportExport";
import { pdf } from "@react-pdf/renderer";
import QrPDF from "./pdf/QrPDF";


Modal.setAppElement("#root");


const OptionsManager = () => {

  const {
    selectedRestaurantId,
    toggleSubMenu,
    showOverlay,
    closeSubMenu,
    openSubMenu,
    settings,
    mainLanguage,
  } = useAppContext();
  
  const { categories, itemsByCategory, menuItems, currentMainCategory } = useMenuContext();
  const { importModalIsOpen, setImportModalIsOpen, closeImportModal  } = useImportExport();
  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);

    
  const { handleExport } = useImportExport();

  const openImportModal = () => setImportModalIsOpen(true);
  const openPdfModal = () => setPdfModalIsOpen(true);
  
  const [pdfLanguage, setPdfLanguage] = useState(
    settings.language && settings.language.length > 0
      ? settings.language[0].value
      : ""
  );
  const closePdfModal = () => {
    setPdfModalIsOpen(false);
    setPdfLanguage("");
  };

  const openQrPdf = async () => {
    const blob = await pdf(
      <QrPDF selectedRestaurantId={selectedRestaurantId}/>
    ).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };
  

  return (
    <>
   
       <Tooltip label="Options">
          <IconButton size="sm" className="btn" bg="var(--grey150)" border={"1px solid var(--borders)"} onClick={toggleSubMenu("CsvActions")}><EllipsisVertical size={16} color="var(--grey700)" /></IconButton>
        </Tooltip>


        {openSubMenu === "CsvActions" && (
          <div className="submenu">
            <UnorderedList>
              <ListItem
                  pointerEvents={menuItems.length === 0 ? "none" : "auto"}
                  cursor={menuItems.length === 0 ? "not-allowed" : "pointer"}
                  onClick={() =>
                  window.open(
                    `https://gudden.app/${selectedRestaurantId}/menu`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }>
                {" "}
                <Text color={"var(--grey700)"} opacity={menuItems.length === 0 ? "0.5" : "1"}>View menu</Text>
                </ListItem>

              {settings?.modules?.shop && (
                <ListItem
                pointerEvents={menuItems.length === 0 ? "none" : "auto"}
                cursor={menuItems.length === 0 ? "not-allowed" : "pointer"}
                onClick={() =>
                    window.open(
                      `https://gudden.app/${selectedRestaurantId}/shop`,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }>
                  {" "}
                  <Text color={"var(--grey700)"} opacity={menuItems.length === 0 ? "0.5" : "1"}>View shop</Text>
                </ListItem>
              )}
              <ListItem
                onClick={() => {
                  openImportModal();
                  closeSubMenu();
                }}>
                  <Text color={"var(--grey700)"}>Import CSV</Text>
                  </ListItem>
              <ListItem
                pointerEvents={menuItems.length === 0 ? "none" : "auto"}
                cursor={menuItems.length === 0 ? "not-allowed" : "pointer"}
                onClick={() => {
                  handleExport();
                  closeSubMenu();
                }}>
                  <Text color={"var(--grey700)"} opacity={menuItems.length === 0 ? "0.5" : "1"}>Export to CSV</Text>
              </ListItem>
              <ListItem 
                pointerEvents={menuItems.length === 0 ? "none" : "auto"}
                cursor={menuItems.length === 0 ? "not-allowed" : "pointer"}
                onClick={openPdfModal}>
                <Text color={"var(--grey700)"} opacity={menuItems.length === 0 ? "0.5" : "1"}>Print</Text>
              </ListItem>
              
              <ListItem 
                pointerEvents={!currentMainCategory ? "none" : "auto"}
                onClick={openQrPdf}>                  
                <Text color={"var(--grey700)"}>QR Code</Text>
              </ListItem>
            
            </UnorderedList>
            <button onClick={closeSubMenu} className="closeSubmenu">
              Cancel
            </button>
          </div>
        )}
        {showOverlay && openSubMenu === "CsvActions" && (
          <div className="overlay" onClick={closeSubMenu}></div>
        )}

      <ImportModal 
        isOpen={importModalIsOpen}
        setImportModalIsOpen={setImportModalIsOpen}
        onClose={closeImportModal}
        
      />
      <PdfSettingsModal
        onClose={closePdfModal}
        isOpen={pdfModalIsOpen}
        categories={categories}
        itemsByCategory={itemsByCategory}
        menuItems={menuItems}
        selectedRestaurantId={selectedRestaurantId}
        currentMainCategory={currentMainCategory}
        mainLanguage={mainLanguage}
        settings={settings}
        selectedLanguage={pdfLanguage}
        setSelectedLanguage={setPdfLanguage}
      />
    </>
  );
};

export default OptionsManager;
