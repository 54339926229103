// OrderModal.js
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Modal from "react-modal";
import { useAppContext } from "../../contexts/AppContext";
import StatusSelector from "./StatusSelector";
import RefundModal from "./RefundModal";
import OrderInvoice from "./OrderInvoice";
import {
  Flex,
  Box,
  Link,
  Text,
  Image,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Tooltip,
  Highlight,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { ShoppingBag, Car, Clock, Phone, Mail, EllipsisVertical } from "lucide-react";
import { pdf } from "@react-pdf/renderer";
import useUsers from "../../hooks/useUsers";
import useOrders from "../../hooks/useOrders";
import OrderHistory from "./OrderHistory";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import PrintButton from "./PrintButton";
import CancellationModal from "../navigation/CancellationModal";

Modal.setAppElement("#root");

const OrderModal = ({
  isOpen,
  onClose,
  order,
  onDelete,
  onUpdate,
  processRefund,
  formatScheduledTime,
}) => {
  const {
    selectedRestaurantId,
    toggleSubMenu,
    openSubMenu,
    closeSubMenu,
    showOverlay,
    mainLanguage,
    generalSettings,
    userData,
    settings,
  } = useAppContext();

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      backdropFilter: "blur(2px)",
      display: "flex",
      paddingTop: "2%",
      paddingBottom: window.innerWidth <= 960 ? "0" : "2%",
      justifyContent: "center",
      alignItems: "flex-start",
      zIndex: "1500",
    },
    content: {
      padding: "0",
      height: window.innerWidth <= 960 ? "-webkit-fill-available" : "auto", 
    },
  };

  const [orderStatus, setOrderStatus] = useState(order?.status);

  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const {
    getDeliverers,
    deliverers,
    setDeliverers,
    selectedDeliverer,
    setSelectedDeliverer,
  } = useUsers(selectedRestaurantId);

  const {
    assignDeliverer,
    handleAddTimeToOrder,
    handleConfirmOrder,
    cancellationNote,
    setCancellationNote,
    updateOrderStatus,
    localOrder,
    setLocalOrder
  } = useOrders(order);

  const [customerPhotoURL, setCustomerPhotoURL] = useState(null);

  const paymentStatusBg = order?.paymentStatus === "paid" ||  order?.paymentStatus === "Paid" ? "var(--success50)" : "var(--error50)";
  const paymentStatusColor = order?.paymentStatus === "paid" ||  order?.paymentStatus === "Paid" ? "var(--success700)" : "var(--error700)";

  const [selectedItem, setSelectedItem] = useState(null); // État pour l'élément sélectionné (commande)
  const {
    isOpen: isCancellationModalOpen,
    onOpen,
    onClose: onCloseCancellationModal,
  } = useDisclosure(); // Hook pour gérer l'ouverture/fermeture du CancellationModal

  useEffect(() => {
    if (order) {
      setLocalOrder(order); // Assurez-vous de mettre à jour localOrder via le hook
      setOrderStatus(order.status);
    }
  }, [order, setLocalOrder]);

  useEffect(() => {
    const fetchDeliverers = async () => {
      if (selectedRestaurantId) {
        try {
          const deliverersData = await getDeliverers(selectedRestaurantId);
          const formattedDeliverers = deliverersData.map((deliverer) => ({
            value: deliverer.id,
            label: `${deliverer.name} ${deliverer.lastName}`,
          }));
          setDeliverers(formattedDeliverers);

          if (order?.delivererId) {
            const currentDeliverer = formattedDeliverers.find(
              (d) => d.value === order.delivererId
            );
            setSelectedDeliverer(currentDeliverer || null);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des livreurs :", error);
        }
      }
    };

    fetchDeliverers();
  }, [selectedRestaurantId, order?.delivererId]);

  useEffect(() => {
    const fetchCustomerPhotoURL = async () => {
      if (order?.customerDetails?.userId) {
        try {
          const userDoc = await getDoc(
            doc(db, "users", order.customerDetails.userId)
          );
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCustomerPhotoURL(userData.photoURL || null);
          }
        } catch (error) {
          console.error("Error fetching user photoURL:", error);
        }
      }
    };

    fetchCustomerPhotoURL();
  }, [order?.customerDetails?.userId]);

  if (!order) return null;

  const openInvoice = async (order) => {
    const blob = await pdf(
      <OrderInvoice order={order} generalSettings={generalSettings} />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const calculateSubtotal = (item) => {
    const price = parseFloat(item.price.replace(",", "."));
    const subtotal = price * item.quantity;
    return subtotal.toFixed(2).replace(".", ",");
  };

  const deliveryFeeNumber = parseFloat(order.deliveryFee);

  const handleDelivererChange = async (selectedOption) => {
    if (selectedDeliverer && selectedDeliverer.value === selectedOption.value) {
      return;
    }
    setSelectedDeliverer(selectedOption);
    try {
      await assignDeliverer(
        order.id,
        selectedOption.value,
        selectedOption.label
      );

      setLocalOrder((prevOrder) => {
        const newHistoryEntry = {
          action: `Assigned to ${selectedOption.label}`,
          timestamp: new Date().toISOString(),
          user: userData
            ? `${userData.name} ${userData.lastName}`
            : "Unknown User",
        };

        return {
          ...prevOrder,
          delivererId: selectedOption.value,
          historyLog: [...(prevOrder.historyLog || []), newHistoryEntry],
        };
      });
    } catch (error) {
      console.error("Error assigning deliverer.");
    }
  };

  const handleStatusUpdate = async (orderId, newStatus, ...rest) => {
    await onUpdate(orderId, newStatus, ...rest);
  
    // Mettre à jour `localOrder` après la mise à jour du statut
    setLocalOrder((prevOrder) => ({
      ...prevOrder,
      status: newStatus,
      historyLog: [
        ...(prevOrder.historyLog || []),
        {
          action: `Status changed to ${newStatus}`,
          timestamp: new Date().toISOString(),
          user: `${userData.name} ${userData.lastName}`,
        },
      ],
    }));
    
    setOrderStatus(newStatus); // Mettre à jour l'état de `orderStatus` local
  };

  // Prompt to open the cancellation modal
  const promptCancel = (order) => {
    setSelectedItem(order);
    setCancellationNote(""); // Reset the cancellation note
    onOpen(); // Open the cancellation modal
  };

  // Handle cancellation
  const handleCancel = async () => {
    if (selectedItem) {
      await updateOrderStatus(
        selectedItem.id,
        "cancelled",
        null,
        cancellationNote
      );
    }
    onCloseCancellationModal(); // Close the cancellation modal
  };

  if (!localOrder) return null; // Attendez que localOrder soit disponible avant d'afficher quoi que ce soit

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className={"modal"}
        style={customStyles}>
        <Flex
          className="modal-content orderModal"
          w={"540px"}
          borderRadius={{
            desktop: "var(--chakra-radii-xl)",
            mobile: "var(--chakra-radii-xl) var(--chakra-radii-xl) 0 0",
          }}
          overflow="hidden">
          <Flex className="modalTopBar" height={"fit-content"}>
            <Flex gap={4}>
              <Box className="closeModal">
                <span className="close" onClick={onClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </Box>
              <Flex
                className="modalTitle"
                direction="row"
                gap={2}
                w="fit-content"
                maxWidth="fit-content"
                alignItems={"center"}>
                Order #{localOrder?.orderId}{" "}
              </Flex>
            </Flex>
            <div className="actionButtons">
              <Flex
                alignItems={"center"}
                gap={1}
                pt={1}
                pb={1}
                pl={2}
                pr={2}
                className="tag"
                bg={paymentStatusBg}
                color={paymentStatusColor}>
                 <Box h="6px" w="6px" borderRadius={"50%"} bg={paymentStatusColor}></Box>{order?.paymentStatus}
              </Flex>

              <StatusSelector
                paymentStatus={localOrder?.paymentStatus}
                currentStatus={localOrder?.status}
                onUpdateStatus={handleStatusUpdate}
                orderId={localOrder?.id}
                deliveryMode={localOrder?.deliveryMode}
              />
              <div
                className="btn simple itemActions subMenuToggle"
                onClick={toggleSubMenu("mainCategoryActions")}>
                <EllipsisVertical size="20px" color="var(--grey600)" _hover={{color: "var(--grey700)"}}/>

                {openSubMenu === "mainCategoryActions" && (
                  <div className="submenu">
                    <ul>
                      <PrintButton
                        order={order}
                        mainLanguage={mainLanguage}
                        deliveryTime={
                          settings.shopSettings.delivery.deliveryTime
                        }
                      />
                      {(order.status === "completed" ||
                        order.paymentStatus === "Paid") && (
                        <li onClick={() => setIsRefundModalOpen(true)}>
                          Refund
                        </li>
                      )}
                      {localOrder?.status === "completed" && (
                        <li onClick={() => openInvoice(order)}>
                          Download invoice
                        </li>
                      )}
                      <li
                        onClick={() => onDelete(selectedRestaurantId, order.id)}
                        style={{ color: "var(--error)" }}>
                        Delete order
                      </li>
                    </ul>
                    <button onClick={closeSubMenu} className="closeSubmenu">
                      Cancel
                    </button>
                  </div>
                )}
                {showOverlay && openSubMenu === "mainCategoryActions" && (
                  <div className="overlay" onClick={closeSubMenu}></div>
                )}
              </div>
            </div>
          </Flex>

          {localOrder?.cancellationNote && order.status === "cancelled" && (
            <Flex bg={"var(--error)"} p={2} gap={1} alignItems={"baseline"}>
              <Text color={"white"} fontSize={"11px"}>
                Cancellation note
              </Text>
              <Text color={"white"}>{localOrder?.cancellationNote}</Text>
            </Flex>
          )}

          <Tabs
            variant="unstyled"
            w="100%"
            display={"flex"}
            flex={1}
            flexDirection={"column"}
            p={0}
            overflow="hidden">
            <Box zIndex={1}>
              <TabList>
                <Tab>Info</Tab>
                <Tab>History</Tab>
              </TabList>

              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="var(--brand)"
                borderRadius="1px"
              />
            </Box>

            <Flex className="modalInner" p={0} flex={1} overflow="hidden">
              <TabPanels display={"flex"} flex={1} overflow="hidden">
                <TabPanel
                  flex={1}
                  display={"flex"}
                  flexDirection={"column"}
                  overflow="hidden">
                  <Flex
                    direction="column"
                    gap={0}
                    flex={1}
                    overflowY={"auto"}
                    style={{ scrollbarWidth: "none" }}>
                    <Flex
                      direction="row"
                      gap={4}
                      p={8}
                      borderBottom={"1px solid var(--borders)"}
                      alignItems={"center"}>
                      {customerPhotoURL ? (
                        <Image
                          w={{ desktop: "62px", mobile: "48px" }}
                          h={{ desktop: "62px", mobile: "48px" }}
                          borderRadius="62px"
                          src={customerPhotoURL}
                          alt="Customer profile picture"
                          fallbackSrc="https://alliancebjjmn.com/wp-content/uploads/2019/07/placeholder-profile-sq-491x407.jpg"
                          objectFit={"cover"}
                        />
                      ) : (
                        <Box
                          w="62px"
                          h="62px"
                          bg="var(--grey100)"
                          borderRadius="62px"></Box>
                      )}

                      <Flex direction="column" gap={1} flex={1}>
                        <Text fontSize={"13px"}>Customer</Text>
                        <Flex direction="column" gap={0} flex={1}>
                          <h6>
                            {localOrder?.customerDetails.firstName}{" "}
                            {localOrder?.customerDetails.lastName}
                          </h6>
                          <Link
                            href={`mailto:${localOrder?.customerDetails.email}`}
                            color="var(--brand)"
                            fontWeight={"500"}>
                            {localOrder?.customerDetails.email}
                          </Link>
                        </Flex>
                      </Flex>
                      <Flex gap={2}>
                        <Tooltip label="Call customer">
                          <Link
                            href={`tel:${localOrder?.customerDetails.phone}`}
                            display="flex"
                            w={"42px"}
                            height={"42px"}
                            bg="var(--grey100)"
                            borderRadius={42}
                            alignItems={"center"}
                            justifyContent={"center"}>
                            <Phone size={20} color="var(--grey500)" />
                          </Link>
                        </Tooltip>
                        <Tooltip label="Email customer">
                          <Link
                            href={`mailto:${localOrder?.customerDetails.email}`}
                            display="flex"
                            w={"42px"}
                            height={"42px"}
                            bg="var(--success50)"
                            borderRadius={42}
                            alignItems={"center"}
                            justifyContent={"center"}>
                            <Mail size={20} color="var(--success)" />
                          </Link>
                        </Tooltip>
                      </Flex>
                    </Flex>

                    <Flex
                      direction="row"
                      gap={{ desktop: 8, mobile: 4 }}
                      p={8}
                      borderBottom={"1px solid var(--borders)"}
                      alignItems={"center"}>
                      <Flex
                        direction={"column"}
                        gap={1}
                        overflow="visible"
                        flex={1}>
                        {localOrder?.deliveryMode === "delivery" ? (
                          <>
                            <Text fontSize={"13px"}>Deliverer</Text>
                            <Flex
                              className="tag deliverer"
                              alignItems="center"
                              gap={1}
                              overflow="visible"
                              w={"100%"}>
                              <Car size={16} color="var(--grey500)" />
                              <Select
                                value={selectedDeliverer}
                                onChange={handleDelivererChange}
                                options={deliverers}
                                placeholder="Select Deliverer"
                                className="customSelect-container"
                                classNamePrefix="customSelect"
                                isSearchable={false}
                              />
                            </Flex>
                          </>
                        ) : (
                          <>
                            {" "}
                            <Text fontSize={"13px"}>Method</Text>
                            <Flex
                              className="tag deliverer"
                              alignItems="center"
                              gap={1}>
                              <ShoppingBag size={16} color="var(--grey500)" />
                              {localOrder?.deliveryMode}
                            </Flex>
                          </>
                        )}
                      </Flex>
                     

                      <Flex direction={"column"} gap={1} flex={1}>
                        <Text fontSize={"13px"}>Scheduled time</Text>
                        <Flex className="tag" alignItems="center" gap={1}>
                          <Clock size={16} color="var(--grey500)" />
                          {localOrder?.scheduledTime
                            ? formatScheduledTime(order.scheduledTime.toDate())
                            : "N/A"}
                        </Flex>
                      </Flex>
                    </Flex>

                    {localOrder?.deliveryMode === "delivery" && (
                      <Flex
                        direction={{ desktop: "row", mobile: "column" }}
                        gap={{ desktop: 16, mobile: 8 }}
                        p={8}
                        borderBottom={"1px solid var(--borders)"}
                        alignItems={"flex-start"}
                        justifyContent={"space-between"}>
                        <Flex direction="row" alignItems={"center"} gap={4}>
                          <Flex direction="column" gap={1} flex={1}>
                            <Text fontSize={"13px"}>Address</Text>
                            {localOrder?.customerDetails.address}{" "}
                            {localOrder?.customerDetails.postalCode}{" "}
                            {localOrder?.customerDetails.city}
                            {localOrder?.deliveryNote &&
                              order.deliveryMode === "delivery" && (
                                <Text fontSize={"13px"} color="var(--error)">
                                  {localOrder?.deliveryNote}
                                </Text>
                              )}
                          </Flex>
                        </Flex>
                      </Flex>
                    )}

                    <Flex direction="column" gap={0} p={0} mt="-1px">
                      <Flex direction="column" gap={4}>
                        <Table size="md">
                          <Thead>
                            <Tr>
                              <Th>Item</Th>
                              <Th isNumeric>Qty</Th>
                              <Th isNumeric>Subtotal</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {localOrder?.cartItems.map((item, index) => (
                              <Tr key={index}>
                                <Td w="80%">
                                  <Flex gap={4} alignItems={"center"}>
                                    <Box
                                      background="var(--grey100)"
                                      display={{
                                        desktop: "block",
                                        mobile: "none",
                                      }}
                                      borderRadius={8}
                                      overflow="hidden"
                                      w="52px"
                                      minWidth="52px"
                                      h="52px">
                                      {item.photo && (
                                        <Image
                                          src={item.photo}
                                          aspectRatio="1/1"
                                          objectFit="cover"
                                          objectPosition="center"
                                          w="52px"
                                          minWidth="52px"
                                          h="52px"
                                        />
                                      )}
                                    </Box>
                                    <Flex direction="column" gap={1}>
                                      <Box fontWeight={"500"}>
                                        <h6>{item[`name_${mainLanguage}`]}</h6>
                                      </Box>
                                      {item.extras && item.extras.length > 0 ? (
                                        <Flex
                                          gap={1}
                                          flex={1}
                                          flexWrap={"wrap"}>
                                          {item.extras.map(
                                            (extra, extraIndex) => (
                                              <Box
                                                bg={"var(--grey100)"}
                                                borderRadius={4}
                                                px={2}
                                                py={1}
                                                fontSize={"11px"}
                                                fontWeight="500"
                                                key={extraIndex}>
                                                {extra.name}
                                              </Box>
                                            )
                                          )}
                                        </Flex>
                                      ) : (
                                        <Text fontSize="11px">No extras</Text>
                                      )}

                                      {item.note && (
                                        <>
                                          <Highlight
                                            query={item.note}
                                            styles={{
                                              px: "1",
                                              py: "0",
                                              bg: "var(--brand500)",
                                              color: "white",
                                              borderRadius: "4px",
                                              fontSize: "13px",
                                              width: "fit-content",
                                            }}>
                                            {item.note}
                                          </Highlight>
                                        </>
                                      )}
                                    </Flex>
                                  </Flex>
                                </Td>
                                <Td isNumeric w="10%">
                                  <Box className="tag" fontWeight={"600"}>
                                    {item.quantity}
                                  </Box>
                                </Td>
                                <Td isNumeric w="10%">
                                  {calculateSubtotal(item)}€
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Flex>

                      <Flex
                        direction="column"
                        gap={4}
                        w="100%"
                        alignSelf="flex-end">
                        <Table size="md">
                          <Tbody>
                            {localOrder?.tip > 0 && (
                              <Tr>
                                <Td>Tip</Td>
                                <Td isNumeric>{localOrder?.tip}€</Td>
                              </Tr>
                            )}
                            {localOrder?.deliveryFee > 0 && (
                              <Tr>
                                <Td>Delivery fee</Td>
                                <Td isNumeric>
                                  {deliveryFeeNumber
                                    .toFixed(2)
                                    .replace(".", ",")}
                                  €
                                </Td>
                              </Tr>
                            )}
                            {order?.couponCode && order.couponCode.trim() !== "" && (
                                <Tr>
                                  <Td>Coupon <strong>{localOrder?.couponCode}</strong></Td>
                                  <Td isNumeric>-{order?.discount.toFixed(2).replace(".", ",")}€</Td>
                                </Tr>
                              )}
                            {localOrder?.serviceFee > 0 && (
                              <Tr>
                                <Td>Service fee</Td>
                                <Td isNumeric>
                                  {localOrder?.serviceFeeTTC
                                    .toFixed(2)
                                    .replace(".", ",")}
                                  €
                                </Td>
                              </Tr>
                            )}

                            {order?.refundedAmount && (
                              <Tr>
                                <Td>
                                  <span style={{ color: "var(--error)" }}>
                                    Refunded
                                  </span>
                                </Td>
                                <Td>
                                  <span style={{ color: "var(--error)" }}>
                                    {localOrder?.refundedAmount}€
                                  </span>
                                </Td>
                              </Tr>
                            )}
                            <Tr>
                          <Td border={"none"}>
                            <strong>Total</strong>
                          </Td>
                          <Td isNumeric border={"none"}>
                            <strong>{localOrder?.total}€</strong>
                          </Td>
                        </Tr>
                          </Tbody>
                        </Table>
                      </Flex>
                    </Flex>
                  </Flex>
                  
                  {localOrder?.status === "pending" && (
                    <Flex
                    direction={"column"}
                    gap={0}
                    bg="white"
                    borderTop="1px solid var(--borders)">
                    
                   
                      <Flex
                        gap="2"
                        borderTop="1px solid var(--borders)"
                        px={4}
                        py={4}
                        bg="white"
                        zIndex={1}
                        height={"fit-content"}>
                        <Button
                          size="md"
                          bg="var(--error)"
                          color="white"
                          _hover={{ backgroundColor: "var(--error700)" }}
                          onClick={(e) => {
                            promptCancel(order);
                          }}>
                          Decline
                        </Button>
                        <Button
                          size="md"
                          bg="var(--success)"
                          color="white"
                          _hover={{ backgroundColor: "var(--success700)" }}
                          onClick={(e) => {
                            handleConfirmOrder(order.id);
                          }}>
                          Accept
                        </Button>
                        <Button
                          size="md"
                          colorScheme="blue"
                          onClick={(e) => {
                            handleAddTimeToOrder(order.id, 15);
                          }}>
                          +15
                        </Button>
                        <Button
                          size="md"
                          colorScheme="blue"
                          onClick={(e) => {
                            handleAddTimeToOrder(order.id, 30);
                          }}>
                          +30
                        </Button>
                        <Button
                          size="md"
                          colorScheme="blue"
                          onClick={(e) => {
                            handleAddTimeToOrder(order.id, 45);
                          }}>
                          +45
                        </Button>
                      </Flex>
                  
                  </Flex>
                    )}
                </TabPanel>
                <TabPanel overflowY={"auto"} style={{ scrollbarWidth: "none" }} w={"100%"}>
                  <OrderHistory order={localOrder} />
                </TabPanel>
              </TabPanels>
            </Flex>
          </Tabs>
        </Flex>
      </Modal>

      <CancellationModal
        isOpen={isCancellationModalOpen}
        onClose={onCloseCancellationModal}
        cancellationNote={cancellationNote}
        setCancellationNote={setCancellationNote}
        handleConfirmCancellation={handleCancel}
      />

      <RefundModal
        isOpen={isRefundModalOpen}
        onRequestClose={() => setIsRefundModalOpen(false)}
        order={order}
        processRefund={processRefund}
      />
    </>
  );
};

export default OrderModal;
