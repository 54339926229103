import React, { useState, useEffect } from "react";
import {
  Flex,
  Button,
  Box,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import useOrders from "../../hooks/useOrders";
import { useAppContext } from "../../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import CancellationModal from "./CancellationModal";
import { Clock, Car, ShoppingBag } from "lucide-react";
import useReservations from "../../hooks/useReservations";
import { useNotifications } from "../../contexts/NotificationsContext";

const Notifications = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selectedRestaurantId, userRole } = useAppContext();
  const {
    updateOrderStatus,
    cancellationNote,
    setCancellationNote,
    formatScheduledTime,
  } = useOrders();
  const { updateReservationStatus } = useReservations();
  const [selectedItem, setSelectedItem] = useState(null);
  const [cancellationType, setCancellationType] = useState(null);

  const { pendingOrders, pendingReservations, isDrawerOpen, setIsDrawerOpen } =
    useNotifications();

  // Initialisation de l'état du tiroir lors du chargement du composant
  const [drawerManuallyClosed, setDrawerManuallyClosed] = useState(() => {
    const savedState =
      sessionStorage.getItem("drawerManuallyClosed") === "true";
    return savedState;
  });

  // Utilisation de useEffect pour vérifier les nouvelles notifications
  useEffect(() => {
    // Récupérer la valeur de fermeture manuelle du sessionStorage
    const drawerWasManuallyClosed =
      sessionStorage.getItem("drawerManuallyClosed") === "true";

    // Récupérer les anciennes longueurs stockées dans sessionStorage
    const oldPendingOrdersLength =
      parseInt(sessionStorage.getItem("prevPendingOrdersLength"), 10) || 0;
    const oldPendingReservationsLength =
      parseInt(sessionStorage.getItem("prevPendingReservationsLength"), 10) ||
      0;

    // Vérification des nouvelles notifications
    const newOrder = pendingOrders.length > oldPendingOrdersLength;
    const newReservation =
      pendingReservations.length > oldPendingReservationsLength;

    if (newOrder || newReservation) {
      // Si une nouvelle notification arrive, on ouvre le tiroir.
      setDrawerManuallyClosed(false); // Réinitialise la fermeture manuelle
      setIsDrawerOpen(true); // Ouvre le tiroir
      sessionStorage.removeItem("drawerManuallyClosed"); // Supprime l'état manuel si une nouvelle notification arrive
    } else if (!newOrder && !newReservation && drawerWasManuallyClosed) {
      // Si aucune nouvelle notification et le tiroir a été fermé manuellement, on le garde fermé.
      setIsDrawerOpen(false);
    }

    // Mettre à jour les valeurs seulement une fois après vérification
    if (
      pendingOrders.length !== oldPendingOrdersLength ||
      pendingReservations.length !== oldPendingReservationsLength
    ) {
      sessionStorage.setItem(
        "prevPendingOrdersLength",
        pendingOrders.length.toString()
      );
      sessionStorage.setItem(
        "prevPendingReservationsLength",
        pendingReservations.length.toString()
      );
    }
  }, [pendingOrders, pendingReservations, setIsDrawerOpen]);

  // Fonction de fermeture manuelle du tiroir
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerManuallyClosed(true); // Marque que l'utilisateur a fermé manuellement
    sessionStorage.setItem("drawerManuallyClosed", "true"); // Stocke cet état dans sessionStorage
  };

  const handleOrderClick = (orderId) => {
    navigate(`/${selectedRestaurantId}/shop`, { state: { orderId } });
    setIsDrawerOpen(false);
  };

  const handleReservationClick = (reservationId) => {
    navigate(`/${selectedRestaurantId}/reservations`, {
      state: { reservationId },
    });
  };

  const promptCancel = (item, type) => {
    setSelectedItem(item);
    setCancellationType(type);
    setCancellationNote(""); // Réinitialiser la note d'annulation
    onOpen();
  };

  const handleCancel = () => {
    if (cancellationType === "order" && selectedItem) {
      updateOrderStatus(selectedItem.id, "cancelled", null, cancellationNote);
    } else if (cancellationType === "reservation" && selectedItem) {
      updateReservationStatus(selectedItem.id, "cancelled", cancellationNote);
    }
    onClose();
  };

  const handleConfirmReservation = (reservationId) => {
    updateReservationStatus(reservationId, "confirmed");
  };

  // Fusionner les commandes et les réservations en attente, puis les trier par date
  const notifications = [
    ...pendingOrders.map((order) => ({ ...order, type: "order" })),
    ...pendingReservations.map((reservation) => ({
      ...reservation,
      type: "reservation",
    })),
  ];

  // Trier par date de création, la plus récente en premier
  notifications.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

  // Ne rien rendre si l'utilisateur est un "deliverer"
  if (userRole === "deliverer") {
    return null;
  }

  return (
    <>
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={handleCloseDrawer}
        size="sm">
        <DrawerOverlay bg={"transparent"} />
        <DrawerContent bg={"white"}>
          <DrawerHeader fontSize={"16px"}>Notifications</DrawerHeader>
          <DrawerCloseButton
            color={"var(--grey500)"}
            borderRadius={"full"}
            bg={"var(--grey100)"}
            _hover={{ color: "var(--grey700)" }}
          />
          <DrawerBody>
            {notifications.length > 0 &&
              notifications.map((notification) => (
                <Flex
                  onClick={() =>
                    notification.type === "order"
                      ? handleOrderClick(notification.id)
                      : handleReservationClick(notification.id)
                  }
                  direction="column"
                  key={notification.id}
                  minWidth="100%"
                  w="100%"
                  maxWidth={"420px"}
                  bg="white"
                  borderRadius="8px"
                  border="1px solid var(--borders)"
                  boxShadow="rgba(149, 157, 165, 0.1) 0px 8px 24px"
                  _hover={{
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                  cursor={"pointer"}
                  mb={4}>
                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={4}
                    p={4}
                    borderBottom="1px solid var(--borders)">
                    <h6>
                      {notification.type === "order"
                        ? `New order`
                        : `New reservation`}
                    </h6>
                    <Flex direction="row" gap={1}>
                      {notification.type === "order" && (
                        <>
                          {notification.deliveryMode === "delivery" ? (
                            <Tooltip label="Delivery">
                              <Flex
                                className="tag deliverer"
                                alignItems="center"
                                gap={1}
                                overflow="visible">
                                <Car size={16} color="var(--grey500)" />
                              </Flex>
                            </Tooltip>
                          ) : (
                            <Tooltip label="Pickup">
                              <Flex
                                className="tag deliverer"
                                alignItems="center"
                                gap={1}>
                                <ShoppingBag size={16} color="var(--grey500)" />
                              </Flex>
                            </Tooltip>
                          )}
                        </>
                      )}
                      <Flex className="tag" alignItems="center" gap={1}>
                        <Clock size={16} color="var(--grey500)" />
                        {notification.scheduledTime
                          ? formatScheduledTime(
                              notification.scheduledTime.toDate()
                            )
                          : "N/A"}
                      </Flex>
                    </Flex>
                  </Flex>

                  {/* Détails spécifiques pour les réservations */}
                  {notification.type === "reservation" && (
                    <>
                      <Box p={4}>
                        <Text fontSize="14px">
                          <strong>Name:</strong>{" "}
                          {notification.customer.firstName}{" "}
                          {notification.customer.lastName}
                        </Text>
                        <Text fontSize="14px">
                          <strong>Party Size:</strong> {notification.guests}
                        </Text>
                        <Text fontSize="14px">
                          <strong>Special Requests:</strong>{" "}
                          {notification.preference || "None"}
                        </Text>
                      </Box>
                      <Flex
                        gap="2"
                        borderTop="1px solid var(--borders)"
                        px={4}
                        py={4}>
                        <Button
                          size="sm"
                          bg="var(--error)"
                          color="white"
                          _hover={{ backgroundColor: "var(--error700)" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            promptCancel(notification, "reservation");
                          }}>
                          Decline
                        </Button>
                        <Button
                          size="sm"
                          bg="var(--success)"
                          color="white"
                          _hover={{ backgroundColor: "var(--success700)" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleConfirmReservation(notification.id);
                          }}>
                          Accept
                        </Button>
                      </Flex>
                    </>
                  )}
                </Flex>
              ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <CancellationModal
        isOpen={isOpen}
        onClose={onClose}
        cancellationNote={cancellationNote}
        setCancellationNote={setCancellationNote}
        handleConfirmCancellation={handleCancel}
      />
    </>
  );
};

export default Notifications;
