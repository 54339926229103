import { NavLink } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import { Flex, Button } from "@chakra-ui/react";
import { CircleGauge, Utensils, Store, Users, EllipsisVertical, CalendarSearch } from "lucide-react";

const Navigation = () => {
  const {
    selectedRestaurantId,
    setMenuAdminBarZIndex,
    toggleSubMenu,
    showOverlay,
    closeSubMenu,
    openSubMenu,
    settings,
    userRole
  } = useAppContext();


  const handleOpenSubMenu = (subMenuName) => {
    setMenuAdminBarZIndex(1);
    toggleSubMenu(subMenuName)();
  };
  const handleLinkClick = () => {
    closeSubMenu(); // Fermer le sous-menu lorsque le lien est cliqué
  };

  const isDisabled = (module) => !module;

  

  return (
    <Flex
      position="fixed" 
      bottom={0}
      zIndex={2}
      left={0}
      w={"100%"}
      margin="auto"
      right={0}
      bg='var(--dark)'
      justifyContent="center"
      px={0}
      py={0}
      >
      
      {selectedRestaurantId ? (
        <>

            {(settings.modules?.shop || settings.modules?.reservations) && (
              <NavLink to={`/${selectedRestaurantId}/dashboard`} end className="mobileNavLink">
                  <CircleGauge color="var(--grey300)" />
                  <span>Dashboard</span>
              </NavLink>
            )}


          <NavLink to={`/${selectedRestaurantId}/`} end className="mobileNavLink">
            <Utensils color="var(--grey300)" />
              <span>Menus</span>
          </NavLink>

          <NavLink to={`/${selectedRestaurantId}/shop`} end className={`mobileNavLink ${isDisabled(settings.modules?.shop) ? 'disabled' : ''}`}>
            <Store color="var(--grey300)" />
            <span>Shop</span>
          </NavLink>
          

          {(settings.modules?.reservations) && (
          <NavLink
            to={`/${selectedRestaurantId}/reservations`}
            className={`mobileNavLink ${isDisabled(settings.modules?.reservations) ? 'disabled' : ''}`}
          >
            <CalendarSearch color="var(--grey300)" />
            <span>Reservations</span>
          </NavLink>)}
          
          
       

          

          {(settings.modules?.shop || settings.modules?.reservations) && (
          <NavLink
            to={`/${selectedRestaurantId}/customers`}
            className={`mobileNavLink ${(isDisabled(settings.modules?.shop) && isDisabled(settings.modules?.reservations)) ? 'disabled' : ''}`}
          >
            <Users />
            <span>Customers</span>
          </NavLink>
          )}


          <Button onClick={() => handleOpenSubMenu("moreActions")} className="mobileNavLink">
            <EllipsisVertical />
            More
          </Button>
         

          {openSubMenu === "moreActions" && (
            <Flex className="submenu">
              <Flex direction="column" gap={0} className="submenuInner">
              {userRole === "superAdmin" && (
                <NavLink to={`/${selectedRestaurantId}/customers`} className={`submenuLink ${(isDisabled(settings.modules?.shop) && isDisabled(settings.modules?.reservations)) ? 'disabled' : ''}`} onClick={handleLinkClick}><span>Customers</span></NavLink>
              )}
              <NavLink to={`/${selectedRestaurantId}/recipes`} className={`submenuLink ${isDisabled(settings.modules?.ratio) ? 'disabled' : ''}`} onClick={handleLinkClick}><span>Stock</span></NavLink>
              <NavLink to={`/${selectedRestaurantId}/staff`} className={`submenuLink ${isDisabled(settings.modules?.staff) ? 'disabled' : ''}`} onClick={handleLinkClick}><span>Staff</span></NavLink>

              
              {userRole === "superAdmin" && (
                  <NavLink to={`/${selectedRestaurantId}/settings/`} className="submenuLink" onClick={handleLinkClick}>Settings</NavLink>
                )}
                
                
                <NavLink className="submenuLink" to='mailto:support@gudden.app?subject=gudden.app technical support request' alt="Technical support" title="Technical support" onClick={handleLinkClick}>Technical support</NavLink>
                <NavLink className="submenuLink" to='mailto:support@gudden.app?subject=gudden.app new feature idea'  alt="New feature idea"  title="New feature idea" onClick={handleLinkClick}>Submit a new feature</NavLink>              
              </Flex>
              <button onClick={closeSubMenu} className="closeSubmenu">
                Cancel
              </button>
          </Flex>
          )}
          {showOverlay && openSubMenu === "moreActions" && (
            <div className="overlay" onClick={closeSubMenu}></div>
          )}

          

        </>
      ) : (
        ""
      )}

   
    </Flex>
  );
};

export default Navigation;
