import React, { useEffect, useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import { Flex, FormLabel, Switch, Button } from "@chakra-ui/react";
import ModalTopBar from "../modal/ModalTopBar";
import { db } from "../../firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";

Modal.setAppElement("#root");

const StripeKeysModal = ({ isOpen, onClose, onSave }) => {
  const { customStyles } = useModal();
  const { selectedRestaurantId, stripeKeys, setStripeKeys, settings, setSettings, loadStripeKeys } = useAppContext();

  const [initialKeys, setInitialKeys] = useState(null); // Store initial keys
  const [hasChanges, setHasChanges] = useState(false);  // Track if there are changes

  // Track form input changes
  const handleStripeKeyChange = (event) => {
    const { name, value } = event.target;
    setStripeKeys((prevKeys) => {
      const newKeys = { ...prevKeys, [name]: value };
      setHasChanges(JSON.stringify(newKeys) !== JSON.stringify(initialKeys));  // Check if form has changed
      return newKeys;
    });
  };

  // Toggle between Test and Live modes
  const handleTestModeToggle = () => {
    setSettings((prevSettings) => {
      const newSettings = {
        ...prevSettings,
        shopSettings: {
          ...prevSettings.shopSettings,
          paymentMethods: {
            ...prevSettings.shopSettings.paymentMethods,
            online: {
              ...prevSettings.shopSettings.paymentMethods.online,
              stripeTestMode: !prevSettings.shopSettings.paymentMethods.online.stripeTestMode,
            },
          },
        },
      };
      setHasChanges(JSON.stringify(newSettings) !== JSON.stringify(settings));  // Check if form has changed
      return newSettings;
    });
  };

  // Save the stripeTestMode to Firestore
  const saveStripeTestMode = async () => {
    const stripeTestMode = settings?.shopSettings?.paymentMethods?.online?.stripeTestMode || false;
    try {
      const restaurantDoc = doc(db, `restaurants/${selectedRestaurantId}/settings`, 'config');
      await updateDoc(restaurantDoc, {
        "shopSettings.paymentMethods.online.stripeTestMode": stripeTestMode,
      });
    } catch (error) {
      console.error("Error saving stripeTestMode:", error);
    }
  };

  // Save the Stripe keys
  const submitStripeKeys = async (event) => {
    event.preventDefault();
    await saveStripeTestMode();
    onSave({
      PublicLive: stripeKeys.PublicLive,
      SecretLive: stripeKeys.SecretLive,
      PublicTest: stripeKeys.PublicTest,
      SecretTest: stripeKeys.SecretTest,
    });
    setHasChanges(false);  // Reset changes after save
  };

  // Load the initial keys when the modal is opened
  useEffect(() => {
    if (isOpen) {
      loadStripeKeys();
      setInitialKeys(stripeKeys); // Store the initial keys for comparison
    }
  }, [isOpen, selectedRestaurantId, stripeKeys]);

  // Reset the form when the modal is closed or canceled
  const handleCancel = () => {
    setStripeKeys(initialKeys);  // Reset to initial keys
    setHasChanges(false);        // No changes after reset
    onClose();
  };

  const stripeTestMode = settings?.shopSettings?.paymentMethods?.online?.stripeTestMode || false;

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onRequestClose={handleCancel} className={"modal"} style={customStyles}>
      <div className="modal-content">
        <form>
          <ModalTopBar title="Set up Stripe API keys" onClose={handleCancel} onSave={submitStripeKeys} disabled={!hasChanges} />
          <Flex className="modalInner" direction="column">
            <Flex alignItems="center" mb={4}>
              <FormLabel htmlFor="stripeTestMode" mb="0">
                Stripe Test Mode
              </FormLabel>
              <Switch id="stripeTestMode" isChecked={stripeTestMode} onChange={handleTestModeToggle} />
            </Flex>

            {stripeTestMode ? (
              <>
                <div className="field">
                  <label htmlFor="PublicTest">Stripe API Publishable Test Key</label>
                  <input
                    type="password"
                    name="PublicTest"
                    value={stripeKeys.PublicTest || ""}
                    onChange={handleStripeKeyChange}
                    placeholder="Enter your Stripe publishable Test key"
                    required={stripeTestMode}
                  />
                </div>
                <div className="field">
                  <label htmlFor="SecretTest">Stripe API Secret Test Key</label>
                  <input
                    type="password"
                    name="SecretTest"
                    value={stripeKeys.SecretTest || ""}
                    onChange={handleStripeKeyChange}
                    placeholder="Enter your Stripe secret Test key"
                    required={stripeTestMode}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="field">
                  <label htmlFor="PublicLive">Stripe API Publishable Live Key</label>
                  <input
                    type="password"
                    name="PublicLive"
                    value={stripeKeys.PublicLive || ""}
                    onChange={handleStripeKeyChange}
                    placeholder="Enter your Stripe publishable Live key"
                    required={!stripeTestMode}
                  />
                </div>
                <div className="field">
                  <label htmlFor="SecretLive">Stripe API Secret Live Key</label>
                  <input
                    type="password"
                    name="SecretLive"
                    value={stripeKeys.SecretLive || ""}
                    onChange={handleStripeKeyChange}
                    placeholder="Enter your Stripe secret Live key"
                    required={!stripeTestMode}
                  />
                </div>
              </>
            )}
          </Flex>
        </form>
      </div>
    </Modal>
  );
};

export default StripeKeysModal;
