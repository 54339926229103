import { useState, useEffect } from "react";
import { useAppContext } from "../../contexts/AppContext";
import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import {  Flex, Text,Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator,} from "@chakra-ui/react";
import useIngredients from "../../hooks/useIngredients";
import { useRecipeContext } from "../../contexts/RecipeContext";
import Select from "react-select";

Modal.setAppElement("#root");

const EditIngredientModal = ({
  ingredient,
  onClose,
  onDelete,
  isOpen,
  isEditing,
}) => {
  const { customStyles } = useModal();
  const { toggleSubMenu, openSubMenu, closeSubMenu, showOverlay } =
    useAppContext();
  const { supplier, setSupplier, supplierOptions } =
    useRecipeContext();
  const { ingredientHasChanges, setIngredientHasChanges, saveIngredient } =
    useIngredients();

  const [articleId, setArticleId] = useState(""); // Ajoute un état pour articleId
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");

  useEffect(() => {
    if (ingredient) {
      setArticleId(ingredient.articleId || ""); // Assure que articleId est bien pris en compte
      setName(ingredient.name || "");
      setUnit(ingredient.unit || "");
      setPrice(ingredient.price || "");
      setQuantity(ingredient.quantity || "");
      setSupplier(ingredient.supplier || { id: "", name: "" });
      setIngredientHasChanges(false);
    }
  }, [ingredient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") setName(value);
    if (name === "unit") setUnit(value);
    if (name === "price") setPrice(value);
    if (name === "quantity") setQuantity(value);
    setIngredientHasChanges(true);
  };

  const handleSelectSupplier = (selectedOption) => {
    setSupplier({
      id: selectedOption.value,
      name: selectedOption.label,
    });
    setIngredientHasChanges(true);
  };

  const handleSaveIngredient = () => {
    const updatedIngredient = {
      ...ingredient,
      articleId, // Assure que articleId est bien préservé
      name,
      unit,
      price,
      quantity,
      supplier: supplier.id ? supplier : { id: "", name: "" }, // Fournit une valeur par défaut si supplier.id n'existe pas
    };
    saveIngredient(updatedIngredient);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={"modal"}
      style={customStyles}>
      <div className="modal-content">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
            <div className="modalTitle">
              {isEditing ? "Edit ingredient" : "Add ingredient"}
            </div>
            <div className="actionButtons">
              {ingredientHasChanges && (
                <button
                  type="submit"
                  className="simple"
                  onClick={() => handleSaveIngredient()}>
                  {isEditing ? "Save changes" : "Save"}
                </button>
              )}

              {isEditing && (
                <div
                  className="btn simple itemActions subMenuToggle"
                  onClick={toggleSubMenu("ingredientActions")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z"
                      fill="black"
                    />
                  </svg>

                  {openSubMenu === "ingredientActions" && (
                    <div className="submenu">
                      <ul>
                        <li
                          onClick={onDelete}
                          style={{ color: "var(--error)" }}>
                          Delete ingredient
                        </li>
                      </ul>
                      <button onClick={closeSubMenu} className="closeSubmenu">
                        Cancel
                      </button>
                    </div>
                  )}
                  {showOverlay && openSubMenu === "ingredientActions" && (
                    <div className="overlay" onClick={closeSubMenu}></div>
                  )}
                </div>
              )}
            </div>
          </div>

          <Tabs variant="unstyled">
            <TabList>
              <Tab>Info</Tab>
              <Tab>History</Tab>
            </TabList>

            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="var(--brand)"
              borderRadius="1px"
            />

            <Flex className="modalInner">
              <TabPanels>
                <TabPanel>
                  <div className="field">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="eg: tomato"
                      value={name || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="unit">Unit</label>
                    <input
                      type="text"
                      name="unit"
                      id="unit"
                      placeholder="eg: Kilos"
                      value={unit || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="price">Price per unit</label>
                    <input
                      type="number"
                      name="price"
                      id="price"
                      placeholder="eg: 3,50"
                      value={price || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="quantity">Stock</label>
                    <input
                      type="number"
                      name="quantity"
                      id="quantity"
                      value={quantity || 0}
                      onChange={handleChange}
                    />
                  </div>
                  
                  <div className="field">
                    <label htmlFor="supplier">Supplier</label>
                    <Select
                      className="guddenSelectContainer"
                      classNamePrefix="guddenSelect"
                      options={supplierOptions}
                      onChange={handleSelectSupplier}
                      value={supplierOptions.find(
                        (option) => option.value === supplier.id
                      )}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                <Flex className="history-log" direction={"column"} gap={2}>
  {ingredient.history && ingredient.history.length > 0 ? (
    ingredient.history.map((log, index) => {
      // Formater la date sans les secondes
      const formattedDate = new Date(log.date).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      const formattedTime = new Date(log.date).toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return (
        <Flex
                          key={index}
                          direction="column"
                          bg="var(--grey100)"
                          p={4}
                          borderRadius={8}>
                          <Text fontWeight="500">{log.action}</Text>
                          <Text fontSize="12px" color="var(--grey500)">
                          {`${formattedDate} ${formattedTime}`}
                          </Text>
                        </Flex>
      );
    })
  ) : (
    <p>No history available.</p>
  )}
</Flex>

                </TabPanel>
              </TabPanels>{" "}
            </Flex>
          </Tabs>
        </form>
      </div>
    </Modal>
  );
};

export default EditIngredientModal;
