import { Flex, Text, Switch, Image, Input, InputLeftAddon, InputGroup } from "@chakra-ui/react";
import { useAppContext } from "../../../contexts/AppContext";
import Select from "react-select";
import useLanguages from "../../../hooks/useLanguages";

const GeneralSettings = ({ handleInputChange }) => {
  const { settings, setSettings, userRole } = useAppContext();
  const { languages } = useLanguages("");
  const primaryLanguageOptions = languages.filter(
    (lang) =>
      !settings.secondaryLanguages ||
      (Array.isArray(settings.secondaryLanguages) &&
        !settings.secondaryLanguages.some(
          (selectedLang) => selectedLang.value === lang.value
        ))
  );
  const secondaryLanguageOptions = languages.filter(
    (lang) =>
      !settings.language ||
      !settings.language.some(
        (selectedLang) => selectedLang.value === lang.value
      )
  );

  const handleLanguageChange = (selectedOption) => {
    const languageData = languages.find(
      (lang) => lang.value === selectedOption.value
    );

    setSettings((prevSettings) => ({
      ...prevSettings,
      language: [
        {
          ...selectedOption,
          flag: languageData ? languageData.flag : "",
        },
      ],
    }));
  };

  const handleSecondaryLanguagesChange = (selectedOptions) => {
    const updatedSecondaryLanguages = selectedOptions.map((option) => {
      const languageWithFlag = languages.find(
        (lang) => lang.value === option.value
      );
      return {
        value: option.value,
        label: option.label,
        flag: languageWithFlag ? languageWithFlag.flag : "",
      };
    });

    // Met à jour l'état settings avec les nouvelles langues secondaires
    setSettings({ ...settings, secondaryLanguages: updatedSecondaryLanguages });
  };

 

  // Format personnalisé pour afficher les drapeaux devant les langues
  const formatOptionLabel = ({ value, label, flag }) => (
    <Flex alignItems={"center"} direction={"row"}>
      {flag && (
        <Image
          src={flag}
          alt=""
          style={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
      )}
      <Text
        textTransform={"capitalize"}
        color={"var(--grey700)"}
        fontWeight={"500"}>
        {label}
      </Text>
    </Flex>
  );

  return (
    <Flex
      direction="column"
      flexGrow="1"
      minW="0"
      gap={{ desktop: 8, mobile: 8 }}
      pt={8}>
      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <h6>Main language</h6>
          <Text maxW={"320px"}>Default language of your menu{settings.modules?.shop && " and shop"}.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1} maxW={"240px"}>
          <Select
            style={{ maxWidth: "220px" }}
            formatOptionLabel={formatOptionLabel}
            name="language"
            value={
              settings.language && settings.language.length > 0
                ? settings.language[0]
                : ""
            }
            onChange={handleLanguageChange}
            options={primaryLanguageOptions}
            className="customSelect-container"
            classNamePrefix="customSelect"
          />
        </Flex>
      </Flex>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <h6>Secondary languages</h6>
          <Text maxW={"320px"}>Other languages for your menu{settings.modules?.shop && " and shop"}.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
          <Select
            isMulti
            formatOptionLabel={formatOptionLabel}
            name="secondaryLanguages"
            value={settings.secondaryLanguages}
            onChange={handleSecondaryLanguagesChange}
            options={secondaryLanguageOptions}
            className="customSelect-container"
            classNamePrefix="customSelect"
          />
        </Flex>
      </Flex>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <h6>Company information</h6>
          <Text maxW={"320px"}>Update your company information</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">Company</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              type="text"
              name="shopSettings.contact.companyName"
              value={settings.shopSettings?.contact?.companyName || ""}
              onChange={handleInputChange}
            />
          </InputGroup>
          
          </Flex>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">Address</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              type="text"
              name="shopSettings.contact.address"
              value={settings.shopSettings?.contact?.address || ""}
              onChange={handleInputChange}
            />
          </InputGroup>
          
        
          </Flex>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">Phone</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              type="text"
              name="shopSettings.contact.phoneNumber"
              value={settings.shopSettings?.contact?.phoneNumber || ""}
              onChange={handleInputChange}
            />
          </InputGroup>
         
          </Flex>

          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">VAT</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              type="text"
              name="shopSettings.contact.vat"
              value={settings.shopSettings?.contact?.vat || ""}
              onChange={handleInputChange}
            />
          </InputGroup>
          
        
          </Flex>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">RCS</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              type="text"
              name="shopSettings.contact.rcs"
              value={settings.shopSettings?.contact?.rcs || ""}
              onChange={handleInputChange}
            />
          </InputGroup>
          
          </Flex>
        </Flex>
      </Flex>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <h6>Social profiles</h6>
          <Text maxW={"320px"}>Set your social profiles to activate links on your menu{settings.modules?.shop && " and shop"}.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)" border="none" boxShadow="0 0 0 1.5px var(--borders)">facebook.com/</InputLeftAddon>
            <Input
              borderRadius={"0 8px 8px 0 !important"} 
              name="shopSettings.contact.social.facebook" 
              type='text' 
              placeholder=''
              value={settings.shopSettings?.contact?.social?.facebook || ""}
              onChange={handleInputChange}
            />
          </InputGroup>
          </Flex>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">instagram.com/</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              name="shopSettings.contact.social.instagram" 
              type='text' 
              placeholder=''
              value={settings.shopSettings?.contact?.social?.instagram || ""}
              onChange={handleInputChange}
            />
          </InputGroup>
          </Flex>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">x.com/</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              name="shopSettings.contact.social.x" 
              type='text' 
              placeholder=''
              value={settings.shopSettings?.contact?.social?.x || ""}
              onChange={handleInputChange}
            />
          </InputGroup>
          </Flex>

          
        </Flex>
      </Flex>

      

      {userRole === "superAdmin" && (
        <Flex
          direction={{ desktop: "row", mobile: "column" }}
          gap={4}
          borderBottom={"1px solid var(--borders)"}
          pb={8}>
          <Flex
            direction={"column"}
            w={{ desktop: "40%", mobile: "100%" }}
            gap={1}>
            <h6>Modules</h6>
          </Flex>
          <Flex direction="column" gap={2} flex={1}>
            
            <Flex
              directionm="row"
              alignItems="center"
              justifyContent="space-between">
              <h6>Shop</h6>
              <Switch
                id="shop-switch"
                isChecked={settings.modules?.shop || false} 
                onChange={(e) => {
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    modules: {
                      ...prevSettings.modules,
                      shop: e.target.checked,
                    },
                  }));
                }}
              />
            </Flex>

            <Flex
              directionm="row"
              alignItems="center"
              justifyContent="space-between">
              <h6>Ratio</h6>
              <Switch
                id="ratio-switch"
                isChecked={settings.modules?.ratio || false} // Assurez-vous que `settings.modules` est défini
                onChange={(e) => {
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    modules: {
                      ...prevSettings.modules,
                      ratio: e.target.checked,
                    },
                  }));
                }}
              />
            </Flex>

            <Flex
              directionm="row"
              alignItems="center"
              justifyContent="space-between">
              <h6>Reservations</h6>
              <Switch
                id="reservations-switch"
                isChecked={settings.modules?.reservations || false} // Assurez-vous que `settings.modules` est défini
                onChange={(e) => {
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    modules: {
                      ...prevSettings.modules,
                      reservations: e.target.checked,
                    },
                  }));
                }}
              />
            </Flex>

            <Flex
              directionm="row"
              alignItems="center"
              justifyContent="space-between">
              <h6>Staff</h6>
              <Switch
                id="staff-switch"
                isChecked={settings.modules?.staff || false} // Assurez-vous que `settings.modules` est défini
                onChange={(e) => {
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    modules: {
                      ...prevSettings.modules,
                      staff: e.target.checked,
                    },
                  }));
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default GeneralSettings;
