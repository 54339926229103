import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Assurez-vous que la config Firebase est importée
import logo from "../images/guddenApp2.svg";
import { Flex, Link, Button, Text } from "@chakra-ui/react";
import { toast } from "react-toastify";

function Login({ onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      // Tentative d'authentification via Firebase Auth
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Récupérer les données de l'utilisateur dans Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      // Vérifier si le document utilisateur existe et si le rôle est 'customer'
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();

        // Si le rôle est "customer", afficher un message d'erreur
        if (userData.role === "customer") {
          toast.error("This account does not exist");
          await auth.signOut(); // Déconnecter immédiatement l'utilisateur
          return;
        }

        // Sinon, rediriger vers la page du restaurant
        onLoginSuccess();
        navigate(`/${localStorage.getItem("lastSelectedRestaurantId")}`);
      } else {
        toast.error("This account does not exist");
      }
    } catch (error) {
      // Gestion des erreurs d'authentification Firebase
      toast.error(error.message);
    }
  };

  return (
    <Flex
      style={{ height: "calc(var(--vh, 1vh) * 100)" }}
      justifyContent="center"
      py={24}
      w="100%"
    >
      <Flex
        direction="column"
        className="login-container"
        gap={16}
        maxWidth="100%"
        w="320px"
        
      >
        <Link href="/" className="logo" title="Retour au menu" style={{ width: "82px" }}>
          <img src={logo} alt="logo" />
        </Link>
        <div className="login-inside">
          <div className="welcome-txt">
            <h3>Welcome!</h3>
            <p>Log in into your account</p>
          </div>

          <form onSubmit={handleSubmit} className="login-form">
            <div className="field">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="field">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </div>
            <Button size="lg" type="submit" className="btn primary" fontSize="16px">
              Log in
            </Button>
            
          </form>
        </div>
        <div className="bottom-text">
          <Text>© Good Robot Sarls. All rights reserved.</Text>
        </div>
      </Flex>
    </Flex>
  );
}

export default Login;
