import { useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAppContext } from '../contexts/AppContext';

const UserAuthHandler = ({ setIsLoggedIn, setLoading }) => {
  const { setSelectedRestaurantId } = useAppContext();
  const FIFTEEN_DAYS_IN_MS = 15 * 24 * 60 * 60 * 1000; // 15 days in milliseconds

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const lastLogin = userData.lastLogin?.toDate(); // Assuming lastLogin is stored as a Firestore timestamp

          if (lastLogin) {
            const now = new Date();
            const timeDifference = now - lastLogin;

            // Check if more than 15 days have passed
            if (timeDifference > FIFTEEN_DAYS_IN_MS) {
              await signOut(auth); // Automatically sign out the user
              setIsLoggedIn(false);
              setLoading(false);
              return;
            }
          }

          // Set restaurant ID if the user is a restaurant owner
          if (userData.role === 'restaurantOwner') {
            setSelectedRestaurantId(userData.restaurantId);
          }

          // Update the last login time
          await updateDoc(userDocRef, {
            lastLogin: new Date(),
          });

          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
      setLoading(false);
    });
  }, [setSelectedRestaurantId, setIsLoggedIn, setLoading]);

  return null;
};

export default UserAuthHandler;
