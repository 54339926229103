import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAppContext } from './AppContext';
import { db } from '../firebaseConfig';
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";

// Créer le contexte
const NotificationContext = createContext();

// Fournisseur du contexte (Provider)
export const NotificationProvider = ({ children }) => {
  
  const { selectedRestaurantId, settings } = useAppContext();
  const [pendingOrders, setPendingOrders] = useState([]);
  const [pendingReservations, setPendingReservations] = useState([]);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  const [pendingReservationsCount, setPendingReservationsCount] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  // Load Pending Orders
  useEffect(() => {
    if (!selectedRestaurantId || !settings?.modules?.shop) {
      setPendingOrders([]);
      return;
    }
  
    const ordersRef = collection(db, `restaurants/${selectedRestaurantId}/orders`);
    const q = query(ordersRef, where("status", "==", "pending"), orderBy("createdAt", "desc"));
  
    // Abonnement pour surveiller les commandes "pending" en temps réel
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedOrders = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      // Mettre à jour l'état avec les commandes récupérées
      setPendingOrders(fetchedOrders);
      setPendingOrdersCount(querySnapshot.docs.length);
    });
  
    // Se désabonner lors du démontage du composant pour éviter les fuites de mémoire
    return () => unsubscribe();
  }, [selectedRestaurantId, settings]);


  // Load pending reservations
  useEffect(() => {
    if (!selectedRestaurantId || !settings?.modules?.reservations) return;

    const reservationsRef = collection(db, `restaurants/${selectedRestaurantId}/reservations`);
    const q = query(reservationsRef, where("status", "==", "pending"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedReservations = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPendingReservations(fetchedReservations);
      setPendingReservationsCount(querySnapshot.docs.length);
    });

    return () => unsubscribe();
  }, [selectedRestaurantId, settings?.modules?.reservations]);

  

  return (
    <NotificationContext.Provider value={{ pendingOrders, pendingReservations, pendingOrdersCount, pendingReservationsCount, isDrawerOpen, setIsDrawerOpen }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useNotifications = () => {
  return useContext(NotificationContext);
};
