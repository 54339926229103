import useOrders from "../hooks/useOrders";
import { Flex, Link, Button, Tooltip, IconButton } from "@chakra-ui/react";
import { Clock, Milestone, Phone } from "lucide-react";

const Deliveries = () => {
  
  const { filteredDeliveryOrders, updateOrderStatus, formatScheduledTime } = useOrders();
  
  const handleMarkAsDelivered = (order) => {
    // Vérifiez si la commande est impayée avant de passer le statut à "completed"
    if (order.paymentStatus === 'unpaid') {
      const userConfirmed = window.confirm(
        "This order is marked as unpaid. By marking it as delivered, you confirm that the client has paid. Do you want to proceed?"
      );
      
      // Si l'utilisateur ne confirme pas, ne pas continuer la mise à jour du statut
      if (!userConfirmed) {
        return;
      }
    }

    // Si la commande est payée ou si l'utilisateur confirme, marquez la commande comme livrée
    updateOrderStatus(order.id, "completed");
  };

  const getWazeLink = (address) => {
    return `https://waze.com/ul?q=${encodeURIComponent(address)}`;
  };

  return (
    <Flex
      padding={{ desktop: "3vw 3vw 124px 3vw", mobile: "3vw 3vw 124px 3vw" }}
      maxW="1320px"
      direction="column"
      gap={8}
      flex="1">
      <h3 mb={4}>Deliveries</h3>

      <Flex
        direction="column"
        gap={{ desktop: 2, mobile: 0 }}
        mx={{ desktop: 0, mobile: -4 }}
        w={{ desktop: "100%", mobile: "100vw" }}>
          {filteredDeliveryOrders.map((order) => {
            return (
              <Flex 
                key={order.orderId}
                direction={{ desktop: "row", mobile: "column" }}
                w="100%"
                alignItems={{ desktop: "center", mobile: "flex-start" }}
                justifyContent={{ desktop: "space-between", mobile: "flex-start" }}
                borderBottom={{ desktop: "none", mobile: "1px solid var(--borders)" }}
                bg={"white"} 
                borderRadius={{ desktop: 8, mobile: "0"}} 
                overflow={"hidden"} 
                py={4}
                px={8}
                gap={2}>
                  
                  <Tooltip label={order.fullDate}>
                    <h6>
                      {order.orderId ? `#${order.orderId}` : "No Order ID"}{" "}
                      {order.customerDetails?.firstName || "No First Name"}{" "}
                      {order.customerDetails?.lastName || "No Last Name"}
                    </h6>
                  </Tooltip>

                  <Flex className="tag" alignItems="center" gap={1}>
                    <Clock size={16} color="var(--grey500)" />
                    {order.scheduledTime
                      ? formatScheduledTime(order.scheduledTime.toDate())
                      : "N/A"}
                  </Flex>

                  <Link color="blue.500" href={getWazeLink(order.customerDetails.address)} isExternal>
                    {order.customerDetails.address}, {order.customerDetails.city}
                  </Link>

                  <Flex gap={2} alignItems={"center"}>
                    <Link href={`tel:${order.customerDetails.phone}`}>
                      <IconButton
                        icon={<Phone size={16} color="var(--grey500)" />}
                      />
                    </Link>
                    
                    <Link href={getWazeLink(order.customerDetails.address)} isExternal>
                      <IconButton
                        icon={<Milestone size={16} color="var(--grey500)" />}
                      />
                    </Link>

                    <Button
                      colorScheme="green"
                      onClick={() => handleMarkAsDelivered(order)}>
                      Mark as delivered
                    </Button>
                  </Flex>
              </Flex>
            );
          })}
      </Flex>
    </Flex>
  );
};

export default Deliveries;
