import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";
import Menu from "./pages/Menu";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import Recipes from "./pages/Recipes";
import Settings from "./pages/Settings";
import { AppProvider, useAppContext } from "./contexts/AppContext";
import UserAuthHandler from "./services/userAuthHandler";
import "./App.css";
import Spinner from "./components/icons/Spinner";
import { auth, db } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackendLayout from "./pages/BackendLayout";
import ShopLayout from "./pages/ShopLayout";
import Invoicing from "./pages/Invoicing";
import { SelectedRestaurantProvider } from "./contexts/SelectedRestaurantContext";
import MenuLayout from "./pages/MenuLayout";
import RecipesLayout from "./pages/RecipesLayout";
import Stock from "./pages/Stock";
import Dashboard from "./pages/Dashboard";
import Reservations from "./pages/Reservations";
import ReservationsLayout from "./pages/ReservationsLayout";
import TablePlanProvider from "./components/reservations/TablePlanProvider";
import Deliveries from "./pages/Deliveries";
import Coupons from "./pages/Coupons";
import Staff from "./pages/Staff";
import useCustomChakra from "./hooks/useCustomChakra";
import { NotificationProvider } from "./contexts/NotificationsContext";
import Notifications from "./components/navigation/Notifications";

function App() {
  
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const { theme } = useCustomChakra();
  


  const getUserRole = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        return userDoc.data().role;
      }
    }
    return null;
  };

  useEffect(() => {
    // Vérifier l'état de l'utilisateur lors du rendu initial
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        const role = await getUserRole();
        setUserRole(role);
      } else {
        setIsLoggedIn(false);
        setUserRole(null);
      }
      setLoading(false); // Le chargement est terminé après la vérification de l'état
    });

    // Se désabonner de onAuthStateChanged pour éviter les fuites de mémoire
    return () => unsubscribe();
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setLoading(true);
  };

  if (loading) {
    return <Spinner />; // Afficher un spinner tant que le rôle n'est pas récupéré
  }

  return (
    <ChakraProvider theme={theme}>
      <AppProvider userRole={userRole} setUserRole={setUserRole}>
        <Router>
          <SelectedRestaurantProvider>
            <NotificationProvider>
          
          <ToastContainer autoClose={3000} hideProgressBar={true} closeOnClick theme="colored" position="bottom-right" />
            <div className="App">
              <UserAuthHandler
                setIsLoggedIn={setIsLoggedIn}
                setLoading={setLoading}
              />
              <AppRoutes
                isLoggedIn={isLoggedIn}
                userRole={userRole}
                handleLoginSuccess={handleLoginSuccess}
                setIsLoggedIn={setIsLoggedIn}    
                setUserRole={setUserRole}         
                setLoading={setLoading}       
              />
              <Notifications />
            </div>
            </NotificationProvider>
          </SelectedRestaurantProvider>
        </Router>
      </AppProvider>
    </ChakraProvider>
  );
}

export default App;


function AppRoutes({ isLoggedIn, userRole, handleLoginSuccess, setIsLoggedIn, setUserRole, setLoading }) {
  const navigate = useNavigate();
  const { selectedRestaurantId } = useAppContext();

  // Redirection automatique si l'utilisateur est "deliverer"
  useEffect(() => {
    if (isLoggedIn && userRole === "deliverer" && selectedRestaurantId) {
      navigate(`/${selectedRestaurantId}/shop/deliveries`);
    }
  }, [isLoggedIn, userRole, selectedRestaurantId, navigate]);


  // Si l'utilisateur n'est pas connecté, afficher la route de login directement
  return (
    <Routes>
      <Route
        path="/login"
        element={<Login onLoginSuccess={handleLoginSuccess} />}
      />

      {/* Routes accessibles uniquement pour le rôle "deliverer" */}
      {userRole === "deliverer" && (
        <Route
          path="/:restaurantId/shop/deliveries"
          element={
            isLoggedIn && userRole === "deliverer" ? (
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                  <Deliveries />
              </BackendLayout>
            ) : (
              <Navigate replace to="/login" />
            )
          }
        />
      )}

      {/* Routes pour tous les autres rôles */}
      
      {userRole !== "deliverer" && isLoggedIn && (
        <>
          <Route
            path="/"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <MenuLayout>
                  <Menu />
                </MenuLayout>
              </BackendLayout>
            }
          />

          <Route
            path="/:restaurantId/dashboard"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <Dashboard />
              </BackendLayout>
            }
          />

          <Route
            path="/:restaurantId"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <MenuLayout>
                  <Menu />
                </MenuLayout>
              </BackendLayout>
            }
          />

          <Route
            path="/:restaurantId/reservations"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <ReservationsLayout>
                  <Reservations />
                </ReservationsLayout>
              </BackendLayout>
            }
          />

          <Route
            path="/:restaurantId/reservations/tableplan"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <ReservationsLayout>
                  <TablePlanProvider />
                </ReservationsLayout>
              </BackendLayout>
            }
          />

          <Route
            path="/:restaurantId/shop"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <ShopLayout>
                  <Orders />
                </ShopLayout>
              </BackendLayout>
            }
          />

          <Route
            path="/:restaurantId/shop/coupons"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <ShopLayout>
                  <Coupons />
                </ShopLayout>
              </BackendLayout>
            }
          />

          <Route
            path="/:restaurantId/shop/invoicing"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <ShopLayout>
                  <Invoicing />
                </ShopLayout>
              </BackendLayout>
            }
          />

          <Route
            path="/:restaurantId/customers"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                  <Customers />
              </BackendLayout>
            }
          />

         

          <Route
            path="/:restaurantId/recipes"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <RecipesLayout>
                  <Recipes />
                </RecipesLayout>
              </BackendLayout>
            }
          />

          <Route
            path="/:restaurantId/recipes/stock"
            element={
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <RecipesLayout>
                  <Stock />
                </RecipesLayout>
              </BackendLayout>
            }
          />

            <Route
              path="/:restaurantId/staff"
              element={
                <ProtectedRoute
                  isAllowed={isLoggedIn}
                  rolesAllowed={["superAdmin", "restaurantOwner"]}
                  userRole={userRole}
                  redirectPath="/"
                >
                  <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                    <Staff />
                  </BackendLayout>
                </ProtectedRoute>
              }
            />


          <Route
            path="/:restaurantId/settings"
            element={
              <ProtectedRoute
                isAllowed={isLoggedIn && userRole === "superAdmin"}
                redirectPath="/"
              >
              <BackendLayout setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading}>
                <Settings userRole={userRole} />
              </BackendLayout>
              </ProtectedRoute>
            }
          />
        </>
      )}

      {/* Redirection par défaut */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}
