import { Flex } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { MenuProvider } from "../contexts/MenuContext";
import { RecipeProvider } from "../contexts/RecipeContext";
import { useAppContext } from "../contexts/AppContext";

function RecipesLayout({ children }) {
  const { selectedRestaurantId, userRole, menuAdminBarZIndex, settings } = useAppContext();

  return (
    <MenuProvider>
      <RecipeProvider>
        {settings.modules.ratio === true ? (
        <><Flex
          justifyContent="space-between"
          direction="row"
          w="100%"
          alignItems="center"
          position="sticky"
          top="0"
          bg={{ desktop: "white", mobile: "var(--grey100)"}}
          px={{ desktop: 8, mobile: 4 }}
          maxWidth="100vw"
          borderBottom="1px solid var(--borders)"
          style={{ zIndex: menuAdminBarZIndex }}>
          <Flex className="tabLinks">
            <NavLink
              className="tabLink"
              end
              to={`/${selectedRestaurantId}/recipes`}>
              Recipes
            </NavLink>
            {userRole === "superAdmin" && 
            <NavLink
              className="tabLink"
              to={`/${selectedRestaurantId}/recipes/stock`}>
              Stock
            </NavLink>
            }
          </Flex>
        </Flex>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        padding={{ desktop: 12, mobile: 8 }}
        pb={{ desktop: 8, mobile: '172px' }} 
        flex={1}
        gap={8}
        maxW="1320px"
      >
        
        {children}
        </Flex>
        </>
        ) : ( 
          null
        )}
      </RecipeProvider>
    </MenuProvider>
  );
}
export default RecipesLayout;
