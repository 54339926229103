import { useState } from "react";
import { Flex, Link, Tooltip } from "@chakra-ui/react";
import UserProfile from "./UserProfile";
import { Bell, Lightbulb, LifeBuoy } from "lucide-react";
import RestaurantSelect from "./RestaurantSelect";
import { useAppContext } from "../../contexts/AppContext";
import { useNotifications } from "../../contexts/NotificationsContext";

const pluralize = (count, singular, plural) => {
  return count === 1 ? singular : plural;
};

const TopHeader = ({ setIsLoggedIn, setUserRole, setLoading }) => {
  const { settings, userRole } = useAppContext();
  const { pendingOrdersCount, pendingReservationsCount, setIsDrawerOpen } = useNotifications();
    
  const totalPendingCount = pendingOrdersCount + pendingReservationsCount;

  const tooltipLabelParts = [];
  if (pendingOrdersCount > 0) {
    tooltipLabelParts.push(
      `${pendingOrdersCount} pending ${pluralize(
        pendingOrdersCount,
        "order",
        "orders"
      )}`
    );
  }
  if (pendingReservationsCount > 0) {
    tooltipLabelParts.push(
      `${pendingReservationsCount} pending ${pluralize(
        pendingReservationsCount,
        "reservation",
        "reservations"
      )}`
    );
  }
  
  const tooltipLabel = totalPendingCount > 0
    ? `You have ${pendingOrdersCount} pending ${pluralize(pendingOrdersCount, "order", "orders")}` +
      `${pendingReservationsCount > 0 ? ` and ${pendingReservationsCount} pending ${pluralize(pendingReservationsCount, "reservation", "reservations")}` : ""}.`
    : "You don't have any notifications";

    const handleNotificationClick = () => {
      if (totalPendingCount > 0) {
        setIsDrawerOpen(true);
      }
    };

  return (
    <Flex
      as="header"
      direction="row"
      w="100%"
      px={{ desktop: 8, mobile: 4 }}
      pt={2}
      pb={2}
      justifyContent={"space-between"}
      alignItems={"center"}
      bg={{ desktop: "white", mobile: "var(--grey100)" }}>
      <RestaurantSelect />

      <Flex direction="row" alignItems="center" gap={2} className="topIcons">
      
        <Tooltip label="Technical support">
        <Link
          href="mailto:support@gudden.app?subject=gudden.app technical support request"
          alt="Technical support"
          display={{ desktop: "flex", mobile: "none" }}>
          <Flex
            w="42px"
            h="42px"
            backgroundColor="var(--grey100)"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px">
            <LifeBuoy size={20} />
          </Flex>
        </Link>
        </Tooltip>
        
        <Tooltip label="New feature idea">
        <Link
          href="mailto:support@gudden.app?subject=gudden.app new feature idea"
          alt="New feature idea"
          display={{ desktop: "flex", mobile: "none" }}>
          <Flex
            w="42px"
            h="42px"
            backgroundColor="var(--grey100)"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px">
            <Lightbulb size={20} />
          </Flex>
        </Link>
        </Tooltip>


        {userRole !== 'deliverer' && 
          (settings?.modules?.shop || settings?.modules?.reservations) && (
    <Tooltip label={tooltipLabel}>
      <Flex
        w={{ desktop: "42px", mobile: "36px" }}
        h={{ desktop: "42px", mobile: "36px" }}
        cursor={"pointer"}
        onClick={handleNotificationClick}
        backgroundColor={{ desktop: "var(--grey100)", mobile: "white" }}
        alignItems="center"
        justifyContent="center"
        borderRadius="8px"
      >
        <Bell size={20} />
        {totalPendingCount > 0 && (
          <Flex 
            justifyContent="center" 
            fontSize={{ desktop: "10px", mobile: "8px" }} 
            fontWeight={"500"} 
            bg="var(--brand)" 
            color="white" 
            borderRadius="50%" 
            height={{ desktop: "16px", mobile: "12px" }} 
            width={{ desktop: "16px", mobile: "12px" }} 
            position="absolute" 
            right={1} 
            top={1}
          >
            {totalPendingCount}
          </Flex>
        )}
      </Flex>
    </Tooltip>
  )
}


        <UserProfile
          setIsLoggedIn={setIsLoggedIn}
          setUserRole={setUserRole}
          setLoading={setLoading}
        />

        
      </Flex>
    </Flex>
  );
};

export default TopHeader;
