import React, { useState, createContext, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAllRestaurants from '../hooks/useAllRestaurants';
import { useAppContext } from './AppContext';

const SelectedRestaurantContext = createContext();

export const useSelectedRestaurant = () => {
  return useContext(SelectedRestaurantContext);
};

export const SelectedRestaurantProvider = ({ children }) => {
  const { restaurants } = useAllRestaurants();
  const { selectedRestaurantId, setSelectedRestaurantId, userRole, userRestaurantIds, isSelectedRestaurantLoading, setIsSelectedRestaurantLoading } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();

  // Filtrer les restaurants selon le rôle de l'utilisateur
  const filteredRestaurants = useMemo(() => {
    if (userRole === 'superAdmin') {
      return restaurants;
    } else if (['restaurantOwner', 'manager', 'deliverer'].includes(userRole)) {
      return restaurants.filter(restaurant => userRestaurantIds.includes(restaurant.id));
    }
    return [];
  }, [restaurants, userRole, userRestaurantIds]);

  const selectedRestaurant = useMemo(() => {
    if (!selectedRestaurantId) {
      return undefined; // Si selectedRestaurantId n'est pas défini
    }
    return filteredRestaurants.find(r => r.id === selectedRestaurantId);
  }, [filteredRestaurants, selectedRestaurantId]);

  // Gestion de la sélection du restaurant au démarrage
  useEffect(() => {
    const lastSelectedRestaurantId = localStorage.getItem('lastSelectedRestaurantId');
    
    if (!selectedRestaurantId) {
      // Vérifie si le restaurant stocké est valide pour l'utilisateur actuel
      if (lastSelectedRestaurantId && filteredRestaurants.some(r => r.id === lastSelectedRestaurantId)) {
        setSelectedRestaurantId(lastSelectedRestaurantId);
        setIsSelectedRestaurantLoading(false);
      } else if (filteredRestaurants.length > 0) {
        // Si non, sélectionne le premier restaurant disponible
        const firstAvailableRestaurantId = filteredRestaurants[0]?.id;
        if (firstAvailableRestaurantId) {
          setSelectedRestaurantId(firstAvailableRestaurantId);
          setIsSelectedRestaurantLoading(false);
          localStorage.setItem('lastSelectedRestaurantId', firstAvailableRestaurantId);
        }
      }
    } else {
      setIsSelectedRestaurantLoading(false);
    }
  }, [filteredRestaurants, selectedRestaurantId, setSelectedRestaurantId]);

  // Logique de navigation après que selectedRestaurantId ait été initialisé
  useEffect(() => {
    if (selectedRestaurantId && !location.pathname.includes(selectedRestaurantId)) {
      navigate(`/${selectedRestaurantId}/`);
    }
  }, [selectedRestaurantId, navigate, location.pathname]);

  // Forcer la redirection si le chemin n'est pas valide pour le restaurant sélectionné
  useEffect(() => {
    const isSuperAdminRoute = location.pathname.includes("/settings");
    const isOrdersRoute = location.pathname.includes("/shop");
    const isRecipesRoute = location.pathname.includes("/recipes");
    const isCustomersRoute = location.pathname.includes("/customers");
    const isDashboardRoute = location.pathname.includes("/dashboard");
    const isReservationsRoute = location.pathname.includes("/reservations");
    const isStaffRoute = location.pathname.includes("/staff");
    
    if (selectedRestaurantId && !isSuperAdminRoute && !isOrdersRoute && !isRecipesRoute && !isCustomersRoute && !isDashboardRoute && !isReservationsRoute && !isStaffRoute) {
      navigate(`/${selectedRestaurantId}/`);
    }
  }, [selectedRestaurantId, navigate, location.pathname]);

  // Redirection automatique si l'utilisateur est "deliverer"
  useEffect(() => {
    if (userRole === "deliverer" && selectedRestaurantId) {
      navigate(`${selectedRestaurantId}/shop/deliveries`);
    }
  }, [userRole, selectedRestaurantId, navigate]);

  const handleChangeRestaurant = (selectedId) => {
    localStorage.setItem('lastSelectedRestaurantId', selectedId);
    setSelectedRestaurantId(selectedId);
    setIsSelectedRestaurantLoading(false);
    const restaurant = restaurants.find(r => r.id === selectedId);
    if (restaurant) {
      handleRestaurantChange(restaurant);
    }
  };

  const handleRestaurantChange = (selectedRestaurant) => {
    if (!selectedRestaurant) {
      console.error("No restaurant selected");
      return;
    }
    setSelectedRestaurantId(selectedRestaurant.id);
    setIsSelectedRestaurantLoading(false);
  };

  const value = {
    selectedRestaurant,
    setSelectedRestaurantId,
    handleChangeRestaurant,
    handleRestaurantChange,
    filteredRestaurants,
    isSelectedRestaurantLoading
  };

  return (
    <SelectedRestaurantContext.Provider value={value}>
      {children}
    </SelectedRestaurantContext.Provider>
  );
};
