import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Flex, useDisclosure, Spinner } from '@chakra-ui/react';
import CancellationModal from '../navigation/CancellationModal';
import useOrders from '../../hooks/useOrders';
import useUsers from '../../hooks/useUsers'; 
import { useAppContext } from '../../contexts/AppContext';
import { X, Clock, Check, Car, CookingPot } from 'lucide-react';
import DelivererModal from './DelivererModal';

const StatusSelector = ({ orderId, currentStatus, onUpdateStatus, deliveryMode, delivererId, paymentStatus }) => {
    
    const { selectedRestaurantId } = useAppContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedValue, setSelectedValue] = useState(null);
    const { cancellationNote, setCancellationNote } = useOrders();
    const { getDeliverers, deliverers, setDeliverers, selectedDeliverer, setSelectedDeliverer } = useUsers(selectedRestaurantId);
    
    const [isUpdating, setIsUpdating] = useState(false); // Nouvel état pour gérer l'affichage du Spinner
    
    const [isDelivererModalOpen, setIsDelivererModalOpen] = useState(false);
   
    const [previousStatus, setPreviousStatus] = useState(null); // Pour garder en mémoire l'ancien statut
    
    const [preselectedDeliverer, setPreselectedDeliverer] = useState(null); // Nouvel état pour garder le deliverer pré-sélectionné

    const allOptions = [
        { value: 'cancelled', label: 'Cancelled', color: 'var(--error700)', bgColor: 'var(--error50)', icon: X },
        { value: 'pending', label: 'Pending', color: 'var(--warning)', bgColor: 'var(--warning50)', icon: Clock },
        { value: 'confirmed', label: 'Preparing', color: 'var(--purple600)', bgColor: 'var(--purple50)', icon: CookingPot },
        { value: 'sent', label: 'Sent', color: 'var(--blue600)', bgColor: 'var(--blue50)', icon: Car },
        { value: 'completed', label: 'Completed', color: 'var(--success700)', bgColor: 'var(--success50)', icon: Check },
    ];

    const options = deliveryMode === 'pickup' 
        ? allOptions.filter(option => option.value !== 'sent') 
        : allOptions;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            cursor: state.isDisabled ? 'not-allowed' : 'pointer', // Désactiver le curseur si l'option est désactivée
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
    };

    const formatOptionLabel = ({ label, color, bgColor, icon: Icon }) => (
        <Flex className='tag' direction="row" alignItems="center" bg={bgColor} color={color} fontSize={13} fontWeight="600" textTransform="initial" whiteSpace="nowrap" gap={1}>
            <Icon color={color} size={16} />
            {label}
        </Flex>
    );

    const handleChange = async (selectedOption) => {
        if (selectedOption.value === currentStatus || isUpdating) {
            return;
        }
        
        if (selectedOption.value === 'completed' && paymentStatus === 'unpaid') {
            const userConfirmed = window.confirm(
                "This order is marked as unpaid. By marking it as completed, you confirm that the client has paid. Do you want to proceed?"
            );
            if (!userConfirmed) {
                return; // Annule la mise à jour si l'utilisateur n'a pas confirmé
            }
        }

        setIsUpdating(true);
        await onUpdateStatus(orderId, selectedOption.value);
        setSelectedValue(selectedOption);
        setIsUpdating(false);
    };


     // Fonction pour éviter la propagation de l'événement
     const stopPropagation = (event) => {
        event.stopPropagation();
    };

    // Fonction pour confirmer l'annulation
    const handleConfirmCancellation = async () => {
        setIsUpdating(true); // Affiche le Spinner pendant la mise à jour
        await onUpdateStatus(orderId, 'cancelled', null, cancellationNote); 
        setCancellationNote(''); // Réinitialiser la note d'annulation
        onClose();
        setIsUpdating(false); // Cache le Spinner après la mise à jour
    };

    // Fonction pour confirmer le livreur
    const handleConfirmDeliverer = async () => {
        if (selectedDeliverer) {
            setIsUpdating(true); // Affiche le Spinner pendant la mise à jour
            await onUpdateStatus(orderId, 'sent', null, null, selectedDeliverer.value, selectedDeliverer.label);
            setIsDelivererModalOpen(false);
            setIsUpdating(false); // Cache le Spinner après la mise à jour
        }
    };

    // Annuler le changement de statut si le livreur n'est pas confirmé
    const handleCancelDelivererSelection = () => {
        setSelectedValue(options.find(option => option.value === previousStatus)); // Remettre l'ancien statut
        setIsDelivererModalOpen(false); // Fermer le modal sans confirmer
    };

    // Préremplir le livreur dans le select si delivererId existe dans Firebase
    useEffect(() => {
        const fetchDeliverersAndPreselect = async () => {
            if (selectedRestaurantId) {
                try {
                    const deliverers = await getDeliverers(selectedRestaurantId);
                    setDeliverers(deliverers);

                    // Pré-sélectionner le livreur correspondant à delivererId s'il existe
                    if (delivererId && deliverers.length > 0) {
                        const matchedDeliverer = deliverers.find(deliverer => deliverer.id === delivererId);
                        if (matchedDeliverer) {
                            const preselected = {
                                value: matchedDeliverer.id,
                                label: `${matchedDeliverer.name} ${matchedDeliverer.lastName}`,
                            };
                            setPreselectedDeliverer(preselected); // Stocker le deliverer pré-sélectionné
                            setSelectedDeliverer(preselected); // Aussi le définir comme deliverer sélectionné
                        }
                    }
                } catch (error) {
                    console.error("Error fetching deliverers:", error);
                }
            }
        };

        if (isDelivererModalOpen) {
            fetchDeliverersAndPreselect(); // Charger les deliverers et pré-sélectionner si le modal s'ouvre
        }
    }, [delivererId, selectedRestaurantId, getDeliverers, isDelivererModalOpen]);

    useEffect(() => {
        const selectedOption = options.find(option => option.value === currentStatus);
        if (selectedOption && selectedOption.value !== selectedValue?.value) {
            setSelectedValue(selectedOption);
        }
    }, [currentStatus, options, selectedValue]);

    return (
        <>
            {isUpdating ? ( // Afficher un spinner si la mise à jour est en cours
                <Spinner size="sm" />
            ) : (
                <div onClick={stopPropagation}> 
                  <Select
                      value={selectedValue}
                      onChange={handleChange}
                      options={options}
                      styles={customStyles}
                      formatOptionLabel={formatOptionLabel}
                      className="statusSelectorContainer"
                      classNamePrefix="statusSelector"
                      isSearchable={false}
                      isOptionDisabled={(option) => option.value === currentStatus} // Désactiver l'option si elle est égale au statut actuel
                  />
                </div>
            )}
            <CancellationModal 
                isOpen={isOpen} 
                onClose={onClose}
                cancellationNote={cancellationNote}
                setCancellationNote={setCancellationNote}
                handleConfirmCancellation={handleConfirmCancellation}
            />

            

            
            <DelivererModal 
                onClose={handleCancelDelivererSelection}
                isOpen={isDelivererModalOpen}
                deliverers={deliverers}
                selectedDeliverer={selectedDeliverer}
                setSelectedDeliverer={setSelectedDeliverer}
                preselectedDeliverer={preselectedDeliverer}
                handleCancelDelivererSelection={handleCancelDelivererSelection}
                handleConfirmDeliverer={handleConfirmDeliverer}
            />

            
        </>
    );
};

export default StatusSelector;
