import React, { useState, useEffect } from 'react';
import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { Button, Flex, Text, Tooltip, Checkbox, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import { Plus, CircleX } from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { useAppContext } from '../../contexts/AppContext';

const ClosingDays = () => {
  const { selectedRestaurantId, settings, setSettings } = useAppContext();
  const [closingDays, setClosingDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isAllDay, setIsAllDay] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure(); // For handling modal state

  useEffect(() => {
    if (settings.shopSettings?.closingDays) {
      setClosingDays(settings.shopSettings.closingDays);
    }
  }, [settings]);

  // Helper function to combine date and time
  const combineDateAndTime = (date, time) => {
    const combined = new Date(date); // Create a new Date object from the selectedDate
    combined.setHours(time.getHours(), time.getMinutes(), 0, 0); // Set the time while keeping the date
    return combined;
  };

  // Add a new closing day
  const handleAddClosingDay = () => {
    if (selectedDate && !closingDays.some(day => getDateMillis(day.date) === selectedDate.getTime())) {
      const closingDay = {
        date: Timestamp.fromDate(selectedDate), // Use Timestamp.fromDate() for correct timestamp storage
        isAllDay,
        startTime: isAllDay
          ? null
          : startTime
          ? Timestamp.fromDate(combineDateAndTime(selectedDate, startTime)) // Combine date and time
          : null,
        endTime: isAllDay
          ? null
          : endTime
          ? Timestamp.fromDate(combineDateAndTime(selectedDate, endTime)) // Combine date and time
          : null,
      };

      const updatedClosingDays = [...closingDays, closingDay];
      setClosingDays(updatedClosingDays);
      saveClosingDaysToFirestore(updatedClosingDays);
      onClose(); // Close the modal
      resetForm(); // Reset the form fields
    } else {
      toast.error("Please select a valid date or the date is already added.");
    }
  };

  // Reset form fields
  const resetForm = () => {
    setSelectedDate(null);
    setStartTime(null);
    setEndTime(null);
    setIsAllDay(true);
  };

  // Remove a closing day
  const handleRemoveClosingDay = (dateToRemove) => {
    const updatedClosingDays = closingDays.filter(day => getDateMillis(day.date) !== getDateMillis(dateToRemove));
    setClosingDays(updatedClosingDays);
    saveClosingDaysToFirestore(updatedClosingDays);
  };

  // Save closing days to Firestore
  const saveClosingDaysToFirestore = async (updatedClosingDays) => {
    if (!selectedRestaurantId) return;
    try {
      const settingsRef = doc(db, `restaurants/${selectedRestaurantId}/settings`, 'config');
      const updatedSettings = {
        ...settings,
        shopSettings: {
          ...(settings.shopSettings || {}),
          closingDays: updatedClosingDays,
        },
      };
      await updateDoc(settingsRef, { 'shopSettings.closingDays': updatedClosingDays });
      setSettings(updatedSettings);
      toast.success('Closing day saved successfully!');
    } catch (error) {
      console.error('Error saving closing days: ', error);
      toast.error('Error saving closing days.');
    }
  };

  // Function to get the timestamp in milliseconds (toMillis) regardless of source
  const getDateMillis = (date) => {
    if (date instanceof Timestamp) {
      return date.toMillis();
    } else if (date instanceof Date) {
      return date.getTime();
    } else {
      return new Date(date).getTime(); // Handle if the day is a string
    }
  };

  // Sort the closing days in chronological order
  const sortedClosingDays = [...closingDays].sort((a, b) => getDateMillis(a.date) - getDateMillis(b.date));

  return (
    <>
      <Flex direction={{ desktop: "row", mobile: "column" }} gap={{ desktop: 16, mobile: 8 }} flex={1}>
        <Button size="md" variant="outline" bg={'white'} onClick={onOpen}>
          Add
        </Button>

        <Flex gap={3} flexWrap={'wrap'} flex={1}>
          {sortedClosingDays.map((day, index) => (
            <Flex key={index} px={4} py={2} justifyContent="space-between" gap={4} alignItems="flex-start" border={'1px solid var(--borders)'} borderRadius={8}>
              <Box>
                <Text color={'var(--grey700)'} fontWeight={"500"}>{new Date(getDateMillis(day.date)).toLocaleDateString()}</Text>
                {day.isAllDay ? (
                  <Text fontSize={".9em"}>All day</Text>
                ) : (
                  <>
                    <Text fontSize={".9em"}>{`${day.startTime ? new Date(getDateMillis(day.startTime)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false}) : "???"} - ${day.endTime ? new Date(getDateMillis(day.endTime)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false}) : "???"}`}</Text>
                  </>
                )}
              </Box>
              <Tooltip label="Remove day">
                <CircleX
                  cursor={'pointer'}
                  size={16}                
                  onClick={() => handleRemoveClosingDay(day.date)}
                  aria-label="Remove day"
                />
              </Tooltip>
            </Flex>
          ))}
        </Flex>
        
        

        {/* Modal for selecting closing day and time */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Select Closing Day</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" gap={4}>
                <DatePicker
                  placeholderText="Select a closing day"
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)} 
                  dateFormat="dd/MM/yyyy"
                  aria-label="Pick a date"
                  minDate={new Date()}
                />
                <Checkbox isChecked={isAllDay} onChange={(e) => setIsAllDay(e.target.checked)}>
                  All day
                </Checkbox>
                {!isAllDay && (
                  <Flex gap={4}>
                    <DatePicker
                      selected={startTime}
                      onChange={(time) => setStartTime(time)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Start"
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      placeholderText="Start time"
                      aria-label="Start time"
                    />
                    <DatePicker
                      selected={endTime}
                      onChange={(time) => setEndTime(time)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="End"
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      placeholderText="End time"
                      aria-label="End time"
                    />
                  </Flex>
                )}
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button className='btn primary' onClick={handleAddClosingDay}>
                Add closing day
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
};

export default ClosingDays;
