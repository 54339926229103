import React, { useState, useEffect } from "react";
import { db } from "../../../firebaseConfig";
import { collection, getDocs, query } from "firebase/firestore";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "/fonts/Poppins/Poppins-Light.ttf",
      family: "Poppins",
      fontWeight: 300,
    },
    {
      src: "/fonts/Poppins/Poppins-Regular.ttf",
      family: "Poppins",
      fontWeight: 400,
    },
    {
      src: "/fonts/Poppins/Poppins-Medium.ttf",
      family: "Poppins",
      fontWeight: 500,
    },
    {
      src: "/fonts/Poppins/Poppins-SemiBold.ttf",
      family: "Poppins",
      fontWeight: 600,
    },
    {
      src: "/fonts/Poppins/Poppins-Bold.ttf",
      family: "Poppins",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: "20mm",
    fontFamily: "Poppins",
    lineHeight: 1,
  },
  printMenu: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10mm",
  },
  menuCategory: {
    breakInside: "avoid",
  },
  categoryHeader: {
    display: "flex",
    flexDirection: "row",
    gap: "6px",
    alignItems: "baseline",
  },
  categoryTitle: {
    textTransform: "uppercase",
    margin: "0 0 3.5mm",
    fontSize: "12px",
    fontWeight: "600",
  },
  categoryDescription: {
    fontSize: "9px",
    fontWeight: "400",
    color: "#667085",
  },
  categoryPrice: {
    fontSize: "11px",
    fontWeight: "600",
  },
  itemGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    columnGap: "10mm",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #eee",
    padding: "6px 0",
    width: "100%",
    gap: "4px",
  },
  itemColumn: {
    width: "47%",
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #eee",
    padding: "6px 0",
    gap: "4px",
  },
  itemHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    borderWidth: 0,
    gap: "8px",
  },
  itemNameRow: {
    display: "flex",
    flexDirection: "row",
    flex: "1 1",
    gap: "4px",
    alignItems: "baseline",
  },
  itemName: {
    fontSize: "10px",
    fontWeight: "500",
    maxWidth: "85%",
  },
  itemDescription: {
    color: "#667085",
    fontSize: "9px",
    maxWidth: "92%",
    lineHeight: "1.2",
    fontWeight: "400",
  },
  itemTags: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1px",
  },
  itemTag: {
    fontSize: "6px",
    color: "black",
    border: "1px solid #eee",
    borderRadius: "16px",
    padding: "2px 5px 2px",
    whiteSpace: "nowrap",
  },
  itemPrice: {
    fontSize: "9px",
    fontWeight: "600",
  },
  variationSeparator: {
    margin: "0 1mm",
    height: "100%",
    backgroundColor: "#eee",
    width: "1px",
  },
  itemVariations: {
    display: "flex",
    flexDirection: "row",
  },
  itemVariation: {
    display: "flex",
    flexDirection: "row",
    gap: "2px",
  },
  itemVariationName: {
    fontSize: "8px",
    color: "black",
    textTransform: "uppercase",
  },
  itemVariationPrice: {
    fontSize: "8px",
    fontWeight: "500",
  },
});

const MenuToPDF = ({
  selectedRestaurantId,
  categories,
  itemsByCategory,
  mainLanguage,
  selectedLanguage,
  currentMainCategory,
  settings,
}) => {
  const [setFrontendTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      if (
        !currentMainCategory ||
        !currentMainCategory.id ||
        !selectedRestaurantId
      ) {
        return;
      }
      try {
        const q = query(
          collection(
            db,
            `restaurants/${selectedRestaurantId}/menu/${currentMainCategory.id}/tags`
          )
        );
        const querySnapshot = await getDocs(q);
        const tagsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFrontendTags(tagsData);
        console.log("tags", tagsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des tags :", error);
      }
    };

    fetchTags();
  }, [currentMainCategory, selectedRestaurantId, setFrontendTags]);

  console.log("itemsByCategory", itemsByCategory);

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.printMenu}>
          {categories
            .filter((cat) => cat.id !== "uncategorized") // Filtrer pour exclure les catégories non classées
            .filter(
              (cat) =>
                itemsByCategory[cat.id] &&
                itemsByCategory[cat.id].some(
                  (item) => item.display && item.display.includes("menu") && item.availability
                )
            )
            .map((category, index) => {
              return (
                <View
                  key={category.id}
                  break={category.pageBreak === "before" ? true : false}
                  style={styles.menuCategory}>
                  <View style={styles.categoryHeader}>
                    <Text
                      style={{
                        ...styles.categoryTitle,
                        color: settings.brandColor,
                      }}>
                      {category[`name_${selectedLanguage}`] ||
                        category[`name_${mainLanguage}`]}
                    </Text>

                    {category.formula && category.formulaPrice && (
                      <Text style={styles.categoryPrice}>
                        {category.formulaPrice.toLocaleString("fr-FR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        €
                      </Text>
                    )}

                    {category[`description_${mainLanguage}`] && (
                      <Text
                        style={{
                          ...styles.categoryDescription,
                        }}>
                        {category[`description_${selectedLanguage}`] ||
                          category[`description_${mainLanguage}`]}
                      </Text>
                    )}
                  </View>

                  <View style={styles.itemGrid}>
                    {itemsByCategory[category.id].map(
                      (item, idx) =>
                        item.display &&
                        item.display.includes("menu") &&
                        item.availability !== false && (
                          <View
                            style={[
                              styles.item,
                              category.columns === "2" && styles.itemColumn,
                            ]}
                            wrap={false}
                            key={idx}>
                            <View style={styles.itemHeader}>
                              <View style={styles.itemNameRow}>
                                {item[`name_${mainLanguage}`] && (
                                  <Text style={{ ...styles.itemName }}>
                                    {item[`name_${selectedLanguage}`] ||
                                      item[`name_${mainLanguage}`]}
                                  </Text>
                                )}

                                <View style={styles.itemTags}>
                                  {Array.isArray(item.tags) &&
                                    item.tags.length > 0 &&
                                    item.tags.map((tag, index) => (
                                      <Text style={styles.itemTag} key={index}>
                                        {tag.label}
                                      </Text>
                                    ))}
                                  {item[`note_${mainLanguage}`] && (
                                    <Text style={styles.itemTag}>
                                      {item[`note_${selectedLanguage}`] ||
                                        item[`note_${mainLanguage}`]}
                                    </Text>
                                  )}
                                </View>
                              </View>

                              {!category.formula && (
                                <View style={styles.itemVariations}>
                                  {Array.isArray(item.variations) &&
                                  item.variations.length > 0 ? (
                                    item.variations.map((variation, index) => (
                                      <React.Fragment key={index}>
                                        {index > 0 && (
                                          <View
                                            style={styles.variationSeparator}
                                          />
                                        )}
                                        <View style={styles.itemVariation}>
                                          <Text
                                            style={styles.itemVariationName}>
                                            {variation.name}
                                          </Text>
                                          <Text
                                            style={{
                                              ...styles.itemVariationPrice,
                                              color: settings.brandColor,
                                            }}>
                                            {variation.price}€
                                          </Text>
                                        </View>
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    <Text
                                      style={{
                                        ...styles.itemPrice,
                                        color: settings.brandColor,
                                      }}>
                                      {item.price}€
                                    </Text>
                                  )}
                                </View>
                              )}
                            </View>
                            {item[`description_${mainLanguage}`] && (
                              <View>
                                <Text style={styles.itemDescription}>
                                  {item[`description_${selectedLanguage}`] ||
                                    item[`description_${mainLanguage}`]}
                                </Text>
                              </View>
                            )}
                          </View>
                        )
                    )}
                  </View>
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};

export default MenuToPDF;
