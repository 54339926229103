import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useAppContext } from "../../contexts/AppContext";
import { Flex, Box, Text, Image, Link, Tooltip } from "@chakra-ui/react";
import { Phone, Mail } from "lucide-react";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";

Modal.setAppElement("#root");

const CustomerModal = ({ isOpen, onClose, selectedCustomer, onDelete }) => {

  const { selectedRestaurantId, toggleSubMenu, openSubMenu, closeSubMenu, showOverlay } = useAppContext();

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      backdropFilter: "blur(2px)",
      display: "flex",
      paddingTop: "2%",
      paddingBottom: window.innerWidth <= 960 ? "0" : "2%",
      justifyContent: "center",
      alignItems: window.innerWidth <= 960 ? "flex-end" : "flex-start",
      zIndex: "1600",
    },
    content: {
      padding: "0",
    },
  };

  const [customerPhotoURL, setCustomerPhotoURL] = useState(null);
  const [mostOrderedItem, setMostOrderedItem] = useState(undefined);

  useEffect(() => {
    const fetchMostOrderedItem = async () => {
      if (selectedCustomer?.customerId) {
        try {
          const ordersRef = collection(db, `restaurants/${selectedRestaurantId}/orders`);
          const customerOrdersQuery = query(ordersRef, where("customerDetails.userId", "==", selectedCustomer.customerId));

          const ordersSnapshot = await getDocs(customerOrdersQuery);

          if (ordersSnapshot.empty) {
            setMostOrderedItem(null);
            return;
          }

          // Initialize an object to count occurrences of each item
          const itemCounts = {};

          // Iterate through each order to count the items
          ordersSnapshot.forEach((doc) => {
            const orderData = doc.data();
            if (orderData.cartItems && orderData.cartItems.length > 0) {
              orderData.cartItems.forEach(item => {
                if (itemCounts[item.name_en]) {
                  itemCounts[item.name_en] += 1;
                } else {
                  itemCounts[item.name_en] = 1;
                }
              });
            } else {
              console.log("No cart items found in this order.");
            }
          });

   
          // Find the most ordered item by finding the key with the highest value in itemCounts
          const mostOrdered = Object.keys(itemCounts).reduce((a, b) => itemCounts[a] > itemCounts[b] ? a : b, null);

          setMostOrderedItem(mostOrdered);

        } catch (error) {
          console.error("Error fetching most ordered item:", error);
          setMostOrderedItem(null); // Set to null if an error occurs
        }
      } else {
        setMostOrderedItem(null); // Reset if no customer is selected
      }
    };

    fetchMostOrderedItem();
  }, [selectedCustomer?.customerId, selectedRestaurantId]);

  useEffect(() => {
    const fetchCustomerPhotoURL = async () => {
      if (selectedCustomer?.customerId) {
        try {
          const userDoc = await getDoc(doc(db, "users", selectedCustomer.customerId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCustomerPhotoURL(userData.photoURL || null);
          }
        } catch (error) {
          console.error("Error fetching user photoURL:", error);
        }
      }
    };

    fetchCustomerPhotoURL();
  }, [selectedCustomer?.customerId]);

  // Reset mostOrderedItem when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      setMostOrderedItem(undefined);
      setCustomerPhotoURL(null);  // Optionally reset photo URL as well
    }
  }, [isOpen]);

  if (!selectedCustomer) {
    return null;
  }

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose} className={"modal"} style={customStyles}>
        <Flex className="modal-content orderModal" w={"540px"} borderRadius={{
          desktop: "var(--chakra-radii-xl)",
          mobile: "var(--chakra-radii-xl) var(--chakra-radii-xl) 0 0",
        }}>

          <Flex className="modalTopBar" height={"fit-content"}>
            <Flex gap={4}>
              <Box className="closeModal">
                <span className="close" onClick={onClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z" fill="black" />
                  </svg>
                </span>
              </Box>
              <Flex className="modalTitle" direction="row" gap={2} w="fit-content" maxWidth="fit-content" alignItems={"center"}></Flex>
            </Flex>
            <div className="actionButtons">
              <div className="btn simple itemActions subMenuToggle" onClick={toggleSubMenu("mainCategoryActions")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM10 12C10 10.8954 10 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z" fill="black" />
                </svg>

                {openSubMenu === "mainCategoryActions" && (
                  <div className="submenu">
                    <ul>
                      <li style={{ color: "var(--error)" }} onClick={onDelete}>Delete customer</li>
                    </ul>
                    <button onClick={closeSubMenu} className="closeSubmenu">Cancel</button>
                  </div>
                )}
                {showOverlay && openSubMenu === "mainCategoryActions" && (
                  <div className="overlay" onClick={closeSubMenu}></div>
                )}
              </div>
            </div>
          </Flex>

          <Flex className="modalInner" p={0}>
            <Flex direction="column" gap={0}>
              <Flex direction="row" gap={4} p={8} borderBottom={"1px solid var(--borders)"} alignItems={"center"}>
                {customerPhotoURL ? (
                  <Image w={{ desktop: "62px", mobile: "48px" }} h={{ desktop: "62px", mobile: "48px" }} borderRadius="62px" src={customerPhotoURL} alt="Customer profile picture" fallbackSrc="https://alliancebjjmn.com/wp-content/uploads/2019/07/placeholder-profile-sq-491x407.jpg" objectFit={"cover"} />
                ) : (
                  <Box w="62px" h="62px" bg="var(--grey100)" borderRadius="62px"></Box>
                )}

                <Flex direction="column" gap={1} flex={1}>
                  <Flex direction="column" gap={0} flex={1}>
                    <h6>{selectedCustomer.firstName} {selectedCustomer.lastName}</h6>
                    <Link href={`mailto:${selectedCustomer.email}`} color="var(--brand)" fontWeight={"500"}>{selectedCustomer.email}</Link>
                    <Text fontSize={"13px"}>Phone number</Text>
                  </Flex>
                </Flex>
                <Flex gap={2}>
                  <Tooltip label="Call customer">
                    <Link href={`tel:${selectedCustomer.phone}`} display="flex" w={"42px"} height={"42px"} bg="var(--grey100)" borderRadius={42} alignItems={"center"} justifyContent={"center"}>
                      <Phone size={20} color="var(--grey500)" />
                    </Link>
                  </Tooltip>
                  <Tooltip label="Email customer">
                    <Link href={`mailto:${selectedCustomer.email}`} display="flex" w={"42px"} height={"42px"} bg="var(--success50)" borderRadius={42} alignItems={"center"} justifyContent={"center"}>
                      <Mail size={20} color="var(--success)" />
                    </Link>
                  </Tooltip>
                </Flex>
              </Flex>
            </Flex>

            {selectedCustomer && (
              <Flex direction={"row"} gap={4} p={8}>
                <Flex direction="column" gap={2} flex={1}>
                  <Text><strong>Orders:</strong> {selectedCustomer.numberOfOrders}</Text>
                  <Text><strong>Total spent:</strong> {selectedCustomer.totalSpent}€</Text>
                  <Text><strong>Average Spent:</strong> {selectedCustomer.averageSpent}€</Text>
                  <Text><strong>Most ordered item:</strong> {mostOrderedItem ? mostOrderedItem : 'N/A'}</Text> {/* Affichage de l'article le plus commandé */}
                </Flex>
                <Flex direction="column" gap={2} flex={1}>
                  <Text><strong>Reservations</strong> {selectedCustomer?.numberOfReservations?.finished}</Text>
                  <Text><strong>No-shows</strong> {selectedCustomer?.numberOfReservations?.noshow}</Text>
                  <Text><strong>Cancelled reservations</strong> {selectedCustomer?.numberOfReservations?.cancelled}</Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default CustomerModal;
