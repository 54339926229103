import React, { useState, useEffect } from 'react';
import { Flex, Table, Tbody, Thead, Th, Tr, Td, Tooltip, IconButton } from "@chakra-ui/react";
import { Plus } from 'lucide-react';
import { db } from '../firebaseConfig'; // Import your Firebase config
import { collection, getDocs } from 'firebase/firestore';
import CouponModal from '../components/shop/CouponModal';
import { useAppContext } from '../contexts/AppContext';

const Coupons = () => {
  const { selectedRestaurantId } = useAppContext();
  const [coupons, setCoupons] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchCoupons = async () => {
    try {
      const couponsRef = collection(db, `restaurants/${selectedRestaurantId}/coupons`);
      const couponsSnapshot = await getDocs(couponsRef);
      const fetchedCoupons = couponsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCoupons(fetchedCoupons);
    } catch (error) {
      console.error("Error fetching coupons: ", error);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, [selectedRestaurantId]);

  const openCouponModal = () => {
    setIsModalOpen(true);
  };

  const closeCouponModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Flex w='100%' direction="column" gap={8}>
      <Flex className="sectionHeader row spaceBetween">
        <Flex gap={4} alignItems={"center"}>
          <h3>Coupons</h3>
          <Tooltip label="Add a new coupon">
            <IconButton size="xs" bg={"var(--brand)"} _hover={{ bg: 'var(--brand600)'}} onClick={openCouponModal}>
              <Plus color="white" size={16} />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex w='100%' direction="column" gap={8}>
        {coupons.length > 0 ? (
          <div className="tableWrapper">
            <Table w="100%">
              <Thead display={{ desktop: "table-header-group", mobile: "none" }}>
                <Tr className="reservationHeader">
                  <Th>Coupon name</Th>
                  <Th>Application for</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody fontSize="14px">
                {coupons.map((coupon) => (
                  <Tr key={coupon.id}>
                    <Td>{coupon.name}</Td>
                    <Td>{coupon.application}</Td>
                    <Td>{coupon.status}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        ) : (
          <p>No coupons found.</p>
        )}
      </Flex>

      {/* Add Coupon Modal */}
      <CouponModal 
        isOpen={isModalOpen} 
        onClose={closeCouponModal} 
        selectedRestaurantId={selectedRestaurantId}
        onCouponAdded={fetchCoupons} // Pass the fetchCoupons function
      />

    </Flex>
  );
};

export default Coupons;
