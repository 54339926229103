// useUsers.js
import { useState, useCallback } from "react";
import { db } from "../firebaseConfig";
import {  updateDoc, doc, query, collection, where, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from 'react-toastify';

const useUsers = (selectedRestaurantId) => {
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null); 
    const [editedUser, setEditedUser] = useState({});
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const functions = getFunctions();
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const [userHasChanges, setUserHasChanges] = useState(false);
    const [deliverers, setDeliverers] = useState([]);
    const [selectedDeliverer, setSelectedDeliverer] = useState(null); // Pour le deliverer sélectionné
    
    const addUserFromCloud = async (newUserData) => {
        setIsAddingUser(true);
        const addUserFunction = httpsCallable(functions, 'addUser');
    
        try {
            await addUserFunction({
                email: newUserData.email,
                password: newUserData.password,
                name: newUserData.name,
                lastName: newUserData.lastName,
                restaurantIds: newUserData.restaurantIds,
                role: newUserData.role
            });
    
            // Après l'ajout, recharge la liste complète pour obtenir l'UID correct
            const q = query(collection(db, 'users'), where("restaurantIds", "array-contains", selectedRestaurantId));
            const usersSnapshot = await getDocs(q);
            const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
            setUsers(usersList);
            setIsUserModalOpen(false);
            setIsAddingUser(false);
            toast.success('User added successfully');
        } catch (error) {
            console.error("Error adding user:", error);
            setIsAddingUser(false);
            toast.error('Error adding user');
        }
    };
    
    

    const handleUserEdit = (user) => {
        setCurrentUser(user);
        setIsUserModalOpen(true);
    };
    

    const handleSaveUser = async (editedUser) => {
        if (!editedUser.id) {
            console.error("User ID is missing or undefined");
            return; // Arrêtez l'exécution si l'ID est manquant
        }
    
        const userRef = doc(db, 'users', editedUser.id);
        
        // Construire les données de mise à jour
        let updateData = {
            name: editedUser.name,
            lastName: editedUser.lastName,
            email: editedUser.email,
            restaurantIds: editedUser.restaurantIds || [],
            role: editedUser.role || 'restaurantOwner',
        };
    
        // Si un mot de passe est fourni lors de l'édition, l'inclure dans la mise à jour
        if (editedUser.password) {
            updateData.password = editedUser.password;
        }
    
        try {
            await updateDoc(userRef, updateData);
            setUsers(prevUsers => prevUsers.map(u => u.id === editedUser.id ? editedUser : u));
            setIsUserModalOpen(false);
            // Réinitialiser le champ password après la sauvegarde
            setEditedUser(prev => ({ ...prev, password: "" }));
        } catch (error) {
            console.error("Error saving user:", error);
        }
    };
    
    
    
    
    const deleteUserFromCloud = async (userId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this user? This action cannot be undone.");
        
        if (!confirmDelete) {
            return; // Si l'utilisateur annule, ne fais rien
        }
        
        setIsDeletingUser(true);
        const functions = getFunctions();
        const deleteUserFunction = httpsCallable(functions, 'deleteUser');
    
        try {
            await deleteUserFunction({ uid: userId });
            setUsers(users.filter(user => user.id !== userId)); // Retirer l'utilisateur de la liste
            setIsUserModalOpen(false); // Ferme la modale
            setIsDeletingUser(false);  // Arrête l'affichage du loader de suppression
            toast.success('User deleted successfully');
        } catch (error) {
            console.error("Error deleting user with cloud function:", error);
            setIsDeletingUser(false);
            toast.error('Error deleting user');
        }
    };
    

    const closeUserModal = () => {
        setIsUserModalOpen(false);
        setUserHasChanges(false);
      };


      

      const getDeliverers = useCallback(async (restaurantId) => {
        const q = query(
          collection(db, 'users'),
          where("restaurantIds", "array-contains", restaurantId),
          where("role", "==", "deliverer")
        );
        const usersSnapshot = await getDocs(q);
        return usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      }, []);
      
      
    
    
    

	return {
	
        users,
        setUsers,
        currentUser,
        setCurrentUser,
        editedUser,
        setEditedUser,
        isUserModalOpen,
        addUserFromCloud,
        closeUserModal,
        deleteUserFromCloud,
        handleUserEdit,
        handleSaveUser,
        setIsUserModalOpen,
        isAddingUser,
        setIsAddingUser,
        isDeletingUser,
        setIsDeletingUser,
        userHasChanges,
        setUserHasChanges,
        getDeliverers,
        deliverers,
        setDeliverers,
        selectedDeliverer,
        setSelectedDeliverer
	};
};

export default useUsers;
