import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import { Flex, Box, Image, IconButton, Tooltip, Link } from "@chakra-ui/react";
import {
  ArrowLeftToLine,
  ArrowRightToLine,
  Utensils,
  Store,
  Settings,
  Power,
  CookingPot,
  CircleGauge,
  CalendarSearch,
  HandPlatter,
  Truck,
  Users
} from "lucide-react";
import logo from "../../images/guddenApp2.svg";
import useLogout from "../../hooks/useLogout";
import useReservations from "../../hooks/useReservations";
import { useNotifications } from "../../contexts/NotificationsContext";

const Navigation = ({ setIsLoggedIn, setUserRole, setLoading }) => {
  
  const {isCollapsed, setIsCollapsed} = useAppContext();

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  useEffect(() => {
    localStorage.setItem("isCollapsed", JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1281 && !isCollapsed) {
        setIsCollapsed(true);
      } else if (window.innerWidth >= 1281 && isCollapsed) {
        setIsCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isCollapsed]);

  const { selectedRestaurantId, settings, userRole } = useAppContext();

  const handleLogout = useLogout(setIsLoggedIn, setUserRole, setLoading);
  const { pendingOrdersCount } = useNotifications();
  const { pendingReservationsCount } = useReservations();
  const isDisabled = (module) => !module;


  return (
    <Flex
      direction="column"
      w={isCollapsed ? "78px" : "220px"}
      minW={isCollapsed ? "78px" : "220px"}
      h="100dvh"
      py={8}
      px={4}
      justifyContent={"space-between"}
      alignItems={"center"}
      bg='var(--dark)'
      position="sticky"
      top="0"
      transition="all 0.35s ease"
      >
      <Flex
        direction="column"
        gap={{ desktop: 24, mobile: 8 }}
        w="100%"
        justifyContent="center"
        alignItems="center">
        <Flex
          direction="row"
          justifyContent={isCollapsed ? "center" : "space-between"}
          alignItems={"center"}
          minHeight="52px"
          w="100%"
          pr={4}
          pl={4}>
          {!isCollapsed && (
            <NavLink to={`/${selectedRestaurantId}/dashboard`}>
              <Image
                src={logo}
                w="52px"
                transition="all .2s ease"
                _hover={{
                  transform: "rotate(-35deg) scale(.95)",
                }}
              />
            </NavLink>
          )}

          <IconButton
            size="sm"
            bg={"rgba(255,255,255,0.05)"}
            _hover={{ bg: "rgba(255,255,255,.1)" }}
            aria-label="Toggle Sidebar"
            icon={
              isCollapsed ? (
                <ArrowRightToLine size={14} color="var(--grey400)" />
              ) : (
                <ArrowLeftToLine size={14} color="var(--grey400)" />
              )
            }
            onClick={toggleCollapse}
          />
        </Flex>

        {selectedRestaurantId ? (
          <Flex
            direction={"column"}
            justifyContent="flex-start"
            w="100%"
            className="navLinks"
            gap={1}>
              
              {(settings.modules?.shop || settings.modules?.reservations) && (
              <Tooltip
                label="Dashboard"
                isDisabled={!isCollapsed}
                placement="right">
                <NavLink
                  to={`/${selectedRestaurantId}/dashboard`}
                  pr={isCollapsed ? 0 : 8}>
                  <Flex direction="row" alignItems="center" gap={2}>
                    <CircleGauge size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Dashboard
                    </Box>
                  </Flex>
                </NavLink>
              </Tooltip>
            )}

            

            <Tooltip label="Menus" isDisabled={!isCollapsed} placement="right">
              <NavLink to={`/${selectedRestaurantId}/`} end>
                <Flex direction="row" alignItems="center" gap={2}>
                  <Utensils size={16} />
                  <Box
                    opacity={isCollapsed ? "0" : "1"}
                    transition="all 0.24s ease">
                    Menus
                  </Box>
                </Flex>
              </NavLink>
            </Tooltip>


            <Tooltip label="Shop" isDisabled={!isCollapsed} placement="right">
                <NavLink to={`/${selectedRestaurantId}/shop`} className={`${isDisabled(settings.modules?.shop) ? 'disabled' : ''}`}>
                  {isCollapsed && pendingOrdersCount > 0 && (
                    <Box
                      as="span"
                      justifyContent="center"
                      className="status"
                      opacity={isCollapsed ? 1 : 0}
                      transitionDelay="1s"></Box>
                  )}
                  <Flex direction="row" alignItems="center" gap={2}>
                    <Store size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Shop
                    </Box>
                  </Flex>
                  {!isCollapsed && pendingOrdersCount > 0 && (
                    <Flex
                      justifyContent="center"
                      className="notification orderCount">
                      {pendingOrdersCount}
                    </Flex>
                  )}
                </NavLink>
              </Tooltip>
              

              {settings.modules?.reservations &&
              <Tooltip
                label="Reservations"
                isDisabled={!isCollapsed}
                placement="right">
                <NavLink to={`/${selectedRestaurantId}/reservations`} className={`${isDisabled(settings?.modules?.reservations) ? 'disabled' : ''}`}>
                  {isCollapsed && pendingReservationsCount > 0 && (
                    <Box
                      as="span"
                      justifyContent="center"
                      className="status"
                      opacity={isCollapsed ? 1 : 0}
                      transitionDelay="1s"></Box>
                  )}
                  <Flex direction="row" alignItems="center" gap={2}>
                    <CalendarSearch size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Reservations
                    </Box>
                  </Flex>
                  {!isCollapsed && pendingReservationsCount > 0 && (
                    <Flex
                      justifyContent="center"
                      className="notification orderCount">
                      {pendingReservationsCount}
                    </Flex>
                  )}
                </NavLink>
              </Tooltip>
              }


              
    

              <Tooltip label="Customers" isDisabled={!isCollapsed} placement="right">
                <NavLink to={`/${selectedRestaurantId}/customers`} className={`${(isDisabled(settings.modules?.shop) && isDisabled(settings.modules?.reservations)) ? 'disabled' : ''}`}>
                  <Flex direction="row" alignItems="center" gap={2}>
                    <Users size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Customers
                    </Box>
                  </Flex>
                </NavLink>
              </Tooltip>
          

              <Tooltip
                label="Stock"
                isDisabled={!isCollapsed}
                placement="right">
                <NavLink to={`/${selectedRestaurantId}/recipes`} className={`${isDisabled(settings.modules?.ratio) ? 'disabled' : ''}`}>
                  <Flex direction="row" alignItems="center" gap={2}>
                    <CookingPot size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Stock
                    </Box>
                  </Flex>
                </NavLink>
              </Tooltip>
            

            

            {userRole === 'deliverer' && 
            <Tooltip
                label="Deliveries"
                isDisabled={!isCollapsed}
                placement="right">
                <NavLink to={`/${selectedRestaurantId}/shop/deliveries`}>
                  <Flex direction="row" alignItems="center" gap={2}>
                    <Truck size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Deliveries
                    </Box>
                  </Flex>
                </NavLink>
              </Tooltip>
            }


              
             
            {(userRole === 'superAdmin' || userRole === 'restaurantOwner') &&
              <Tooltip
                label="Staff"
                isDisabled={!isCollapsed}
                placement="right">
                <NavLink to={`/${selectedRestaurantId}/staff`}>
                  <Flex direction="row" alignItems="center" gap={2}>
                    <HandPlatter size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Staff
                    </Box>
                  </Flex>
                </NavLink>
              </Tooltip>
            }
              
            

            
          </Flex>
        ) : (
          ""
        )}
      </Flex>

      <Flex
        direction={"column"}
        justifyContent="flex-start"
        w="100%"
        className="navLinks"
        gap={1}>
        {userRole === "superAdmin" && (
          <Tooltip label="Settings" isDisabled={!isCollapsed} placement="right">
            <NavLink to={`/${selectedRestaurantId}/settings`}>
              <Flex direction="row" alignItems="center" gap={2}>
                <Settings size={16} />
                <Box
                  opacity={isCollapsed ? "0" : "1"}
                  transition="all 0.24s ease">
                  Settings
                </Box>
              </Flex>
            </NavLink>
          </Tooltip>
        )}
        <Tooltip label="Logout" isDisabled={!isCollapsed} placement="right">
          <Link onClick={handleLogout} textDecoration={"none"} _hover={{ textDecoration: "none" }}>
            <Flex direction="row" alignItems="center" gap={2}>
              <Power size={16} />
              <Box
                opacity={isCollapsed ? "0" : "1"}
                transition="all 0.24s ease">
                Logout
              </Box>
            </Flex>
          </Link>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Navigation;
