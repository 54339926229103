import { useEffect } from "react";

const useModal = () => {
    
    
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            backdropFilter: 'blur(2px)',
            display: 'flex',
            paddingTop: '2%',
            paddingBottom: '2%',
            justifyContent: 'center',
            zIndex: '99',
        },
    };

    const adjustHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      };
    
      useEffect(() => {
        adjustHeight();
        window.addEventListener('resize', adjustHeight);
        return () => window.removeEventListener('resize', adjustHeight);
      }, []);

    return {
        customStyles, 
        adjustHeight
    };
};

export default useModal;
