// useCategoryData.js

import { useState, useEffect, useCallback } from "react";
import { db, storage } from "../firebaseConfig";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  writeBatch,
  deleteField,
  query,
  setDoc,
} from "firebase/firestore";
import {
  ref as storageRef,
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { toast } from "react-toastify";
import { useAppContext } from "../contexts/AppContext";
import { useMenuContext } from "../contexts/MenuContext";

const useMenuData = () => {
  const {
    selectedRestaurantId,
    mainCategoryName,
    settings,
    mainLanguage,
  } = useAppContext();

  const {
    categories,
    setCategories,
    itemsByCategory,
    setItemsByCategory,
	  menuItems, setMenuItems,
    tags, setTags,
  } = useMenuContext();

  
  const [currentItem, setCurrentItem] = useState({
    price: "",
    costPrice: "",
    ratio: "",
    note: "",
    allergenes: "",
    variations: "",
    type: "",
    id: "",
    photo: "",
    categorieId: "",
    tags: "",
    display: "",
    vat: "",
  });
  const [categoryOrderChanged, setCategoryOrderChanged] = useState(false);
  const [uncategorizedItems, setUncategorizedItems] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
        if (!selectedRestaurantId || !mainCategoryName) {
            setIsLoading(false);
            return;
        }

        const menuSnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`));
        const categorySnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}-categories/categories`));

        const fetchedMenuItems = menuSnapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .sort((a, b) => a.order - b.order);

        const fetchedCategories = categorySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.order - b.order);

        setMenuItems(fetchedMenuItems);
        setCategories(fetchedCategories);
        updateUncategorizedItems(fetchedMenuItems, fetchedCategories);
    } catch (error) {
        console.error("Error fetching data: ", error);
    } finally {
        setIsLoading(false);
    }
}, [mainCategoryName, selectedRestaurantId, setCategories]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const newItemsByCategory = categories.reduce((acc, category) => {
      acc[category.id] = menuItems.filter(
        (item) => item.categorieId === category.id
      );
      return acc;
    }, {});
    setItemsByCategory(newItemsByCategory);
  }, [menuItems, categories, setItemsByCategory]);


  useEffect(() => {
    setCurrentItem(currentItem);
  }, [currentItem, setCurrentItem]);

  const updateUncategorizedItems = (items, categories) => {
    const uncategorizedCategory = categories.find((cat) => cat.isUncategorized);
    const uncategorizedCategoryId = "uncategorized";
    const newUncategorizedItems = items.map((item) => {
      if (!item.categorieId || item.categorieId === uncategorizedCategory?.id) {
        return { ...item, categorieId: uncategorizedCategoryId };
      }
      return item;
    });
    setUncategorizedItems(
      newUncategorizedItems.filter(
        (item) => item.categorieId === uncategorizedCategoryId
      )
    );
  };

  const handleOrderChange = async (newOrder) => {
    const batch = writeBatch(db);
    newOrder.forEach((category, index) => {
      if (!category || !category.id) {
        console.error("Invalid category data:", category);
        return;
      }
      const categoryRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}-categories/categories`,
        category.id
      );
      batch.update(categoryRef, { order: index });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error("Error updating order of categories: ", error);
    }
  };

  const handleCategoryUpdate = (updatedCategory) => {
    setCategories(
      categories.map((category) => {
        if (category.id === updatedCategory.id) {
          return { ...category, ...updatedCategory };
        }
        return category;
      })
    );
    document.body.classList.remove("no-scroll");
  };

  const deleteSubCategory = async (category) => {
    if (!category || !category.id) {
      console.error("Invalid category object or missing ID.");
      return;
    }
    try {
      // Suppression de la sous-catégorie
      const subCategoryDocRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}-categories/categories`,
        category.id
      );
      await deleteDoc(subCategoryDocRef);

      // Mise à jour des éléments pour les passer en 'uncategorized'
      await updateMenuItemsToUncategorized(category.id);

      // Recharger les données
      fetchData();
      toast.success("Category deleted successfully");
    } catch (error) {
      console.error("Error deleting sub-category:", error);
      toast.error("Error deleting sub-category");
    }
  };

  const updateMenuItemsToUncategorized = async (categoryId) => {
    const batch = writeBatch(db);

    menuItems.forEach((item) => {
      if (item.categorieId === categoryId) {
        const itemRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
          item.id
        );
        batch.update(itemRef, { categorieId: "uncategorized" });
      }
    });

    try {
      await batch.commit();
      console.log("Menu items updated to uncategorized successfully.");
    } catch (error) {
      console.error("Error updating menu items to uncategorized:", error);
    }
  };

  const handleSubmit = async (
    e,
    productType,
    variations,
    selectedAllergenes,
    selectedTags,
    selectedVAT
  ) => {
    e.preventDefault();
    
    let formattedPrice;
    
    // Si le type de produit n'est pas "variable", vérifier si le prix est valide
    if (productType !== "variable") {
      let numericPrice = parseFloat(currentItem.price?.replace(",", "."));
      
      if (!isNaN(numericPrice)) {
        formattedPrice = numericPrice.toFixed(2).replace(".", ",");
      } else {
        formattedPrice = ""; // ou null ou undefined selon tes préférences
      }
    }
    
    const tagIds = selectedTags.map((tag) => tag.value);
  
    let updatedItem = {
      ...currentItem,
      allergenes: selectedAllergenes,
      tags: tagIds,
      type: productType,
      ...(productType !== "variable" && formattedPrice !== "" && { price: formattedPrice }),
      ...(selectedVAT !== undefined && { VAT: selectedVAT }),
    };
  
    if (!currentItem.id) {
      updatedItem.availability = true;
    }
  
    const languages = [settings.language[0].value, ...settings.secondaryLanguages.map(lang => lang.value)];
    languages.forEach(lang => {
      if (updatedItem[`name_${lang}`]) {
        updatedItem[`name_${lang}`] = updatedItem[`name_${lang}`].trim();
      }
      if (updatedItem[`description_${lang}`]) {
        updatedItem[`description_${lang}`] = updatedItem[`description_${lang}`].trim();
      }
    });
  
    // Gestion du type et des variations
    if (productType === "variable") {
      updatedItem.variations = variations.map((variation) => ({
        ...variation,
        price: parseFloat(variation.price.replace(",", "."))
          .toFixed(2)
          .replace(".", ","),
      }));
      delete updatedItem.price; // Supprimer le prix pour les produits variables
    } else {
      delete updatedItem.variations; // Supprimer les variations pour les produits simples
    }
  
    try {
      if (currentItem.id) {
        const menuItemDocRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
          currentItem.id
        );
        const updates = {
          ...updatedItem,
          tags: selectedTags || [],
        };
  
        if (productType === "variable") {
          updates.price = deleteField();
        } else {
          updates.variations = deleteField();
        }
        if (updatedItem.photo === "") {
          updates.photo = deleteField();
        }
        await updateDoc(menuItemDocRef, updates);
      } else {
        // Ajout d'un nouvel item
        const docRef = await addDoc(
          collection(
            db,
            `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`
          ),
          updatedItem
        );
        updatedItem.id = docRef.id;
        await updateDoc(docRef, { id: docRef.id });
      }
  
      // Rafraîchir la liste et fermer le modal
      fetchData();
      closeEditModal(true); // Cela forcera la fermeture du modal sans vérification
      toast.success("Item saved successfully");
    } catch (error) {
      console.error("Error saving item data:", error);
      toast.error("Error saving item");
    }
  };
  

  const duplicateItem = async (itemToDuplicate) => {
    try {
      // Vérifie si mainLanguage est défini et non vide
      if (typeof mainLanguage !== "string" || !mainLanguage.trim()) {
        throw new Error("mainLanguage is undefined or empty.");
      }

      let newItem = {
        ...itemToDuplicate,
        // Utilise mainLanguage pour créer le nom correctement
        [`name_${mainLanguage}`]: `${
          itemToDuplicate[`name_${mainLanguage}`]
        } (Copy)`,
      };

      delete newItem.id; 

      const docRef = await addDoc(
        collection(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`
        ),
        newItem
      );
      await updateDoc(docRef, { id: docRef.id }); 

      fetchData();
      closeEditModal(true);
      toast.success("Item duplicated successfully");
    } catch (error) {
      console.error("Error duplicating item:", error);
      toast.error("Error duplicating item");
    }
  };

  // MODAL

  const openEditModal = (itemToEdit) => {
    setCurrentItem(itemToEdit);
    const editItemModal = document.querySelector("#edit-item-modal");
    const modalContent = editItemModal.querySelector(".modal-content");
    document.body.classList.add("no-scroll");
    editItemModal.style.display = "flex";
    editItemModal.style.backgroundColor = "";
    modalContent.classList.remove("modal-content-exiting");
    modalContent.classList.add("modal-content-animating");
    setTimeout(() => {
      modalContent.scrollTop = 0;
    }, 0);
  };

  const closeEditModal = (forceClose = false) => {
    if (!forceClose && hasChanges) {
      const confirmClose = window.confirm(
        "Changes were made. Are you sure that you want to close without saving ?"
      );
      if (!confirmClose) {
        return;
      }
    }
    const editItemModal = document.querySelector("#edit-item-modal");
    const modalContent = editItemModal.querySelector(".modal-content");
    setTimeout(() => {
      modalContent.classList.add("modal-content-exiting");
      modalContent.addEventListener(
        "animationend",
        () => {
          editItemModal.style.display = "none";
          modalContent.classList.remove("modal-content-exiting");
          document.body.classList.remove("no-scroll");

          setCurrentItem({
            price: "",
            note: "",
            allergenes: "Ask Waiter",
            variations: "",
            type: "",
            id: "",
            photo: "",
            categorieId: "",
            tags: "",
            display: "",
            vat: "",
          });
          setHasChanges(false);
          
        },
        { once: true }
      );
    }, 50);
  };

  const addItemWithCategory = (categorieId) => {
    setCurrentItem({
      price: "",
      costPrice: "",
      ratio: "",
      allergenes: "",
      variations: "",
      type: "simple",
      id: "",
      photo: "",
      categorieId: categorieId,
      display: "menu",
      vat: "0.03",
    });
    const editItemModal = document.querySelector("#edit-item-modal");
    editItemModal.style.display = "flex";
  };

  const confirmAndDelete = (id) => {
    if (window.confirm("Are you sure that you want to delete this item?")) {
      handleDelete(id);
      closeEditModal(true);
    }
  };

  const handleDelete = async (id) => {
    await deleteDoc(
      doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
        id
      )
    );
    fetchData();
    toast.success("Item deleted successfully");
  };

  useEffect(() => {
    if (categoryOrderChanged) {
      fetchData();
      setCategoryOrderChanged(false);
    }
  }, [categoryOrderChanged, fetchData]);

  useEffect(() => {
    updateUncategorizedItems(menuItems, categories);
  }, [menuItems, categories]);

  
  const deleteUncategorizedItems = async () => {
    if (
      window.confirm("Are you sure that you want to delete all uncategorized items?")
	  ){
      const batch = writeBatch(db);
      const itemsToDelete = menuItems.filter(
        (item) => !item.categorieId || item.categorieId === "uncategorized"
      );
      itemsToDelete.forEach((item) => {
        const itemRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`, item.id);
        batch.delete(itemRef);
      });

      try {
        await batch.commit();
        toast.success("Uncategorized items deleted successfully");
        fetchData();
      } catch (error) {
        toast.error("Error deleting uncategorized items");
      }
    }
  };

  // useItemImage

  const uploadImage = async (file) => {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `${selectedRestaurantId}/${mainCategoryName}/images` + file.name
    );
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      setCurrentItem({ ...currentItem, photo: url });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      uploadImage(file);
    }
    setHasChanges(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setHasChanges(true);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      await uploadImage(file);
    }
    setHasChanges(true);
  };

  const handleImageDelete = async () => {
    if (currentItem.photo) {
      const imageRef = storageRef(storage, currentItem.photo);
      try {
        await deleteObject(imageRef); // Supprimer l'image de Firebase Storage
      } catch (error) {
        console.error("Error deleting image from Storage:", error);
      }
    }
    setCurrentItem({ ...currentItem, photo: "" });
    setHasChanges(true);
  };

  const handleImageUploadClick = () => {
    document.getElementById("image-upload").click();
  };


  const toggleAvailability = async (item) => {
    const newAvailability = !item.availability; // Inverse l'état de disponibilité
    const itemRef = doc(db,`restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,item.id);
  
    // Mise à jour de l'état de disponibilité dans Firestore
    await updateDoc(itemRef, {
      availability: newAvailability,
    });
  
    // Mettez à jour l'état local après la mise à jour
    const updatedItemsByCategory = {
      ...itemsByCategory,
      [item.categorieId]: itemsByCategory[item.categorieId].map((currentItem) =>
        currentItem.id === item.id
          ? { ...currentItem, availability: newAvailability }
          : currentItem
      ),
    };
    setItemsByCategory(updatedItemsByCategory);
  };
  

  // TAGS

   
    const fetchTags = async () => {
      if (!selectedRestaurantId || !mainCategoryName) {
        return;
      }
      const querySnapshot = await getDocs(
        query(
          collection(
            db,
            `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/tags`
          )
        )
      );
      const tagsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTags(tagsData);
    };
  useEffect(() => {
    fetchTags();
  }, [selectedRestaurantId, mainCategoryName, setTags]);

  const saveTag = async (tag) => {
    const dbPath = `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/tags`;
    try {
      const tagData = {
        ...tag,
      };
      if (!tagData.color) { // S'assurer qu'une couleur est toujours définie
        tagData.color = 'var(--grey150)';
      }
      if (tag.id) {
        await setDoc(doc(db, dbPath, tag.id), tagData, { merge: true });
      } else {
        const newTagId = doc(collection(db, dbPath)).id;
        tagData.id = newTagId;
        await setDoc(doc(db, dbPath, newTagId), tagData);
      }
      fetchTags(); // Assurez-vous que cette fonction recharge les tags à partir de Firestore pour refléter les changements
    } catch (error) {
      console.error(`Error saving tag:`, error);
    }
};


  const confirmDeleteTag = async (tagId, onUpdateItems) => {
    if (window.confirm("Are you sure you want to delete this tag?")) {
      try {
        const tagRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/tags`,
          tagId
        );
        await deleteDoc(tagRef);
        setTags(tags.filter((t) => t.id !== tagId));
        toast.success(`Tag deleted successfully`);
        fetchData();
        if (onUpdateItems) {
          onUpdateItems(tagId);
        }
      } catch (error) {
        console.error("Error deleting tag:", error);
      }
    }
  };

  const onUpdateItems = async (tagIdToRemove) => {
    const batch = writeBatch(db);

    menuItems.forEach((item) => {
      if (item.tags && item.tags.some((tag) => tag.value === tagIdToRemove)) {
        const newTags = item.tags.filter((tag) => tag.value !== tagIdToRemove);

        console.log("Updating item:", item.id, "; New tags:", newTags);

        const itemRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,item.id);
        batch.update(itemRef, { tags: newTags });
      }
    });

    try {
      await batch.commit();
      console.log("Items updated successfully after tag deletion.");
      fetchData();
    } catch (error) {
      console.error("Error updating items:", error);
    }
  };

  return {
    mainCategoryName,
    selectedRestaurantId,
    fetchData,
    menuItems,
    setMenuItems,
    currentItem,
    setCurrentItem,
    handleSubmit,
    duplicateItem,
    updateDoc,
    addDoc,
    deleteField,
    handleOrderChange,
    handleDelete,
    handleCategoryUpdate,
    deleteSubCategory,
    itemsByCategory,
    setItemsByCategory,
    confirmAndDelete,
    openEditModal,
    addItemWithCategory,
    closeEditModal,
    handleImageDelete,
    handleImageUpload,
    handleImageUploadClick,
    handleDrop,
    handleDragOver,
    hasChanges,
    setHasChanges,
    uncategorizedItems,
    deleteUncategorizedItems,
    updateUncategorizedItems,
    onUpdateItems,
    saveTag,
    confirmDeleteTag,
    isLoading,
    toggleAvailability
  };
};

export default useMenuData;
