import { useAppContext } from "../../../contexts/AppContext";
import { Flex, Text, Button, Link, Input, Switch } from "@chakra-ui/react";

const ReservationsSettings = ({ onChange }) => {
  const { selectedRestaurantId, settings, setSettings, userRole } =
    useAppContext();

  return (
    <Flex
      direction="column"
      flexGrow="1"
      minW="0"
      gap={{ desktop: 8, mobile: 8 }}
      pt={8}>
      {userRole === "superAdmin" ? (
        <>
          <Flex
            direction="column"
            flexGrow="1"
            minW="0"
            gap={{ desktop: 8, mobile: 8 }}>
            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              alignItems={"flex-start"}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pb={8}>
              <Flex
                direction={"column"}
                w={{ desktop: "40%", mobile: "100%" }}
                gap={1}>
                <h6>Service duration</h6>
                <Text maxW={"320px"}>Enter time in minutes</Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                <Input
                  type="number"
                  name="reservationsSettings.serviceDuration"
                  value={settings.reservationsSettings?.serviceDuration}
                  onChange={onChange}></Input>
              </Flex>
            </Flex>

            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              alignItems={"flex-start"}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pb={8}>
              <Flex
                direction={"column"}
                w={{ desktop: "40%", mobile: "100%" }}
                gap={1}>
                <h6>Auto-confirm reservations</h6>
                <Text maxW={"320px"}>
                  This will mark incoming reservations as accepted automatically
                  and send an email to the customer with the reservation
                  details.
                </Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                <Switch
                  id="autoconfirm-switch"
                  isChecked={
                    settings.reservationsSettings?.autoConfirm || false
                  } // Assurez-vous que `settings.modules` est défini
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      reservationsSettings: {
                        ...prevSettings.reservationsSettings,
                        autoConfirm: e.target.checked,
                      },
                    }));
                  }}
                />
              </Flex>
            </Flex>
          </Flex>

          {/* SIDEBAR */}

          <Flex
            flex={{ desktop: "0 0 320px", mobile: "1" }}
            direction="column"
            gap={{ desktop: 8, mobile: 0 }}></Flex>
        </>
      ) : (
        <Flex>
          <Text>Reservations module is not activated.</Text>
          <Link
            href={`mailto:it@goodrobot.lu?subject=Reservations module activation request for ${selectedRestaurantId}`}>
            <Button size="lg" className="primary">
              Contact us to activate it.
            </Button>
          </Link>
        </Flex>
      )}
    </Flex>
  );
};

export default ReservationsSettings;
